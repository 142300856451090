import { svg } from 'lit';

const penToSquare = svg`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_6_3741)">
<path d="M13.7812 1.84065L14.1594 2.21877C14.4531 2.51252 14.4531 2.98752 14.1594 3.27815L13.25 4.19065L11.8094 2.75002L12.7188 1.84065C13.0125 1.5469 13.4875 1.5469 13.7781 1.84065H13.7812ZM6.55625 8.00627L10.75 3.8094L12.1906 5.25002L7.99375 9.44378C7.90313 9.5344 7.79062 9.60002 7.66875 9.6344L5.84062 10.1563L6.3625 8.32815C6.39688 8.20627 6.4625 8.09377 6.55312 8.00315L6.55625 8.00627ZM11.6594 0.781274L5.49375 6.94377C5.22188 7.21565 5.025 7.55002 4.92188 7.91565L4.02812 11.0406C3.95312 11.3031 4.025 11.5844 4.21875 11.7781C4.4125 11.9719 4.69375 12.0438 4.95625 11.9688L8.08125 11.075C8.45 10.9688 8.78438 10.7719 9.05313 10.5031L15.2188 4.34065C16.0969 3.46252 16.0969 2.03752 15.2188 1.1594L14.8406 0.781274C13.9625 -0.0968506 12.5375 -0.0968506 11.6594 0.781274ZM2.75 2.00002C1.23125 2.00002 0 3.23127 0 4.75002V13.25C0 14.7688 1.23125 16 2.75 16H11.25C12.7688 16 14 14.7688 14 13.25V9.75002C14 9.3344 13.6656 9.00002 13.25 9.00002C12.8344 9.00002 12.5 9.3344 12.5 9.75002V13.25C12.5 13.9406 11.9406 14.5 11.25 14.5H2.75C2.05938 14.5 1.5 13.9406 1.5 13.25V4.75002C1.5 4.0594 2.05938 3.50002 2.75 3.50002H6.25C6.66563 3.50002 7 3.16565 7 2.75002C7 2.3344 6.66563 2.00002 6.25 2.00002H2.75Z" fill="#C5C8E2"/>
</g>
<defs>
<clipPath id="clip0_6_3741">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
`;

export { penToSquare };
