/* eslint-disable lit/attribute-value-entities */
import { css, CSSResult, html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators';
import { bookmark } from '../../../../assets/icons/bookmark';

import '@mch/nn-web-viz/dist/nn-button';
import '@mch/nn-web-viz/dist/nn-icon';
import '@mch/nn-web-viz/dist/nn-markdown';

class AdeleResultMessageHeader extends LitElement {
  @property({ type: Object }) data: any = {};

  static styles: CSSResult = css`
    * {
      box-sizing: border-box;
    }

    :host {
      display: flex;
      width: 100%;
    }

    nn-markdown {
      margin: 1rem 0;
    }

    .container {
      display: flex;
      align-items: center;
    }
  `;

  dispatch(eventName: string) {
    this.dispatchEvent(new CustomEvent(eventName));
  }

  _renderAddToCollection() {
    if (this.data.index !== 0) return nothing;

    return html`<nn-button
      id="addToCollection"
      nn-analytics-id="add-to-collection-button"
      nn-analytics-description="Open the add to collection sidebar"
      class="add-to-collection__button"
      @click=${() => this.dispatch('add-to-collection-clicked')}
      ?disabled=${!this.data?.id}
      ghost
      ><div class="button__content">
        <nn-icon .svg=${bookmark}></nn-icon><span>ADD TO COLLECTION</span>
      </div></nn-button
    >`;
  }

  render() {
    const title = this.data?.question || this.data?.title || 'TITLE';

    return html`
      <div class="container">
        <nn-markdown
          markdown="<h1>${title.replace(/\n/g, '<br />')}</h1>"
          .styles=${[
            css`
              h1 {
                font-family: Poppins;
                font-size: 1rem;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: -0.02em;
                margin: 0;
              }
            `,
          ]}
        ></nn-markdown>
      </div>
    `;
  }
}

customElements.define('adele-result-message-header', AdeleResultMessageHeader);
