import { PropertyDeclarations, css } from 'lit';
import { NNBase, html } from '../../base/Base';

import '@vaadin/accordion';
import '@vaadin/details';

import { chevronDown } from '../../icons/chevron-down';
import { chevronUp } from '../../icons/chevron-up';
import { scrollbarStyles } from '../../styles/scrollbar';

class NNAccordion extends NNBase {
  static properties: PropertyDeclarations = {
    ...super.properties,
    backgroundColor: { type: String, attribute: 'background-color' },
    minHeight: { type: String, attribute: 'min-height' },
    maxHeight: { type: String, attribute: 'max-height' },
    opened: { type: Boolean },
  };

  declare backgroundColor: string;

  declare minHeight: string;

  declare maxHeight: string;

  declare opened: boolean;

  constructor() {
    super();

    this.opened = false;
  }

  static styles: any = [
    ...scrollbarStyles,
    css`
      :host {
        --lumo-primary-text-color: red;
      }

      .wrapper {
        padding: 2rem;
        background: var(--nn-accordion-background-color, #0f111e);
        border-radius: 8px;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }

      h2 {
        margin: 0;
      }

      .content {
        margin-top: 0.5rem;
        height: 100%;
        min-height: var(--nn-accordion-min-height, 0px);
        max-height: var(--nn-accordion-max-height, 100px);
        overflow: auto;
      }

      ::slotted([slot='footer']) {
        margin-top: 1rem;
      }

      nn-icon {
        height: 16px;
        width: 16px;
      }
    `,
  ];

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('backgroundColor')) {
      this.style.setProperty(
        '--nn-accordion-background-color',
        this.backgroundColor
      );
    }
    if (changedProps.has('maxHeight')) {
      this.style.setProperty('--nn-accordion-max-height', this.maxHeight);
    }
    if (changedProps.has('minHeight')) {
      this.style.setProperty('--nn-accordion-min-height', this.minHeight);
    }
  }

  _openedChanged({ detail: { value } }) {
    this.opened = value;
  }

  render() {
    const chevronDownSvg: any = chevronDown;
    const chervonUpSvg: any = chevronUp;

    return html`
      <div class="wrapper">
        <vaadin-details
          theme="reverse"
          @opened-changed=${e => this._openedChanged(e)}
          opened
        >
          <div slot="summary" class="header">
            <h2>${this.title}</h2>
            ${this.opened
              ? html`<nn-icon .svg=${chervonUpSvg}></nn-icon>`
              : html`<nn-icon .svg=${chevronDownSvg}></nn-icon>`}
          </div>
          <div class="content">
            <slot name="content"></slot>
          </div>
          <div class="footer=">
            <slot name="footer"></slot>
          </div>
        </vaadin-details>
      </div>
    `;
  }
}

export { NNAccordion };
