import { css } from 'lit';

export const AdeleUploadFileOverlayStyles = [
  css`
    * {
      box-sizing: border-box;
    }

    :host {
      width: 800px;
      height: 687px;
      box-sizing: border-box;
      border-radius: 15px;

      padding: 16px 19px;
      border-radius: 16px;

      background: rgba(28, 31, 40);
      box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(18px);

      color: #ffffff;
      font-weight: 800;
    }

    .wrapper {
      height: 100%;
      padding: 0.25rem 1rem 1rem;
      display: flex;
      flex-direction: column;
    }

    .header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 0.5rem;
    }

    .custom-select {
      min-width: 350px;
    }

    select {
      appearance: none;
      /* safari */
      -webkit-appearance: none;
      /* other styles for aesthetics */
      width: 100%;
      font-size: 1.15rem;
      padding: 0.675em 6em 0.675em 1em;
      background-color: #ffffff20;
      border: 0px solid black;
      border-radius: 0.25rem;
      color: #fff;
      cursor: pointer;
    }

    option {
      color: black;
    }

    .custom-file-upload {
      border: 0px solid #ffffff20;
      display: inline-block;
      padding: 6px 12px;
      cursor: pointer;
    }

    .content__wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }

    .upload__wrapper {
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    .vaadin-upload__wrapper {
      flex: 1;
      overflow: auto;
    }

    vaadin-upload {
      background: #ffffff20;
      color: #fff;
    }

    vaadin-upload-file::part(start-button),
    vaadin-upload-file::part(retry-button),
    vaadin-upload-file::part(status),
    vaadin-upload-file > vaadin-progress-bar {
      display: none;
    }

    span {
      color: #fff;
    }

    .footer-actions {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .error {
      color: red;
      font-size: 0.8rem;
    }

    .new-source__wrapper {
      margin-top: 1rem;
    }

    #new-source,
    #new-source-description {
      border-radius: 8px;
      border: 0px solid black;
      color: fff;

      padding-left: 25px;
      padding-right: 25px;
      width: 100%;
      height: 50px;
      font-size: 16px;
      backdrop-filter: blur(4px);
      /* box-shadow: 0px 24px 30px 0px rgba(28, 31, 40, 0.1); */
    }

    vaadin-button {
      color: #fff !important;
      background: #ffffff20;
    }

    label {
      font-weight: normal;
    }
  `,
];
