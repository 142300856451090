import { CSSResult, PropertyDeclarations } from 'lit';

import { NNBase, html } from '../../base/Base';
import { NNCircleStyles } from './NNCircle.styles';

import { lightBlack } from '../../styles';

class NNCircle extends NNBase {
  static properties: PropertyDeclarations = {
    ...super.properties,
    /**
     * The value of the circle
     * Should be from 0 to 1
     */
    value: { type: Number },
    color: { type: CSSResult },
    innerColor: { type: CSSResult },
    outlined: { type: Boolean, attribute: 'outlined' },
    gradient: { type: Boolean, attribute: 'gradient' },
  };

  static styles: any = [NNCircleStyles];

  declare value: number;

  declare outlined: boolean;

  declare color: CSSResult | string;

  declare innerColor: CSSResult;

  declare gradient: boolean;

  constructor() {
    super();

    this.innerColor = lightBlack;
    this.value = 1;
  }

  updated(changedProperties) {
    super.updated(changedProperties);

    if (changedProperties.has('color')) {
      this.style.setProperty('--nn-circle-color', this.color.toString());
      this.style.setProperty(
        '--nn-inner-circle-color',
        this.innerColor.toString()
      );
    }
    if (changedProperties.has('value')) {
      if (this.gradient) {
        this.style.setProperty('--nn-circle-size', `${70 * this.value}px`);
      } else {
        this.style.setProperty('--nn-circle-size', `${60 * this.value}px`);
      }
      this.style.setProperty('--nn-circle-font-size', `${32 * this.value}px`);
    }
  }

  render() {
    return html`<div class="circle">
      <slot></slot>
    </div>`;
  }
}

export { NNCircle };
