/* eslint-disable import/no-extraneous-dependencies */
import { property } from 'lit/decorators';
import { v4 as uuidv4 } from 'uuid';
import { NNBase, html, nothing } from '@mch/nn-web-viz/dist/packages/base/Base';
import { Notification } from '@vaadin/notification';
import {
  registerStyles,
  css as vaadinCss,
} from '@vaadin/vaadin-themable-mixin';
import { connect, store } from '../../state/store';

import '@vaadin/select';
import '@vaadin/upload';

import {
  mongoFunctionsClient,
  uploadFunctionsClient,
} from '../../modules/functions/client';
import { scrollbarStyles } from '../../assets/styles/scrollbar';
import { xmark } from '../../assets/icons/xmark';

import '@mch/nn-web-viz/dist/nn-button';
import '@mch/nn-web-viz/dist/nn-icon';

import ThreadService from '../../service/ThreadService';
import { AdeleUploadFileOverlayStyles } from './AdeleUploadFileOverlayStyles';
import { FileUploadOverlayMode } from './enums';
import { AccountState } from '../../state/slices/accounts';

registerStyles(
  'vaadin-notification-card',
  vaadinCss`
    [part='overlay'] {
    padding: 16px 19px;
    border-radius: 16px;
    background: rgba(28, 31, 40);
    box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(18px);
    color: #ffffff;
    font-weight: 800;
    }
    [part='content'] {
      background: transparent;
    }
  `
);

const showNotification = (type: string, message: string, details: string) => {
  const icon = type === 'Success' ? 'vaadin:check-circle' : 'vaadin:warning';

  const notification = Notification.show(
    html`
      <vaadin-horizontal-layout
        theme="spacing"
        style="align-items: center; color: white;"
      >
        <vaadin-icon icon="${icon}" style="color: white;"></vaadin-icon>
        <div style="margin-left: 10px;">
          <b>${message}</b>
          <div style="font-size: var(--lumo-font-size-s);">${details}</div>
        </div>
        <vaadin-button
          theme="tertiary-inline"
          @click="${() => notification.close()}"
          aria-label="Close"
          style="margin-left: auto; color: white;"
        >
          <vaadin-icon icon="lumo:cross"></vaadin-icon>
        </vaadin-button>
      </vaadin-horizontal-layout>
    `,
    {
      position: 'bottom-end',
      duration: 10000,
    }
  );
};
class AdeleFileUploadOverlay extends connect(store)(NNBase) {
  @property({ type: Number, attribute: 'selected-source' }) selectedSource:
    | number
    | null = null;

  @property({ type: String, attribute: 'thread-name' }) threadName:
    | string
    | null = null;

  @property({ type: String, attribute: 'thread-id' }) threadId: string | null =
    null;

  @property({ type: Array }) sources: Array<any> = [];

  @property({ type: String }) mode: FileUploadOverlayMode =
    FileUploadOverlayMode.SOURCE;

  @property({ type: String }) _selectedSource: string | null = null;

  @property({ type: String }) _newSourceName: string | null = null;

  @property({ type: String }) _newSourceDescription: string | null = null;

  @property({ type: String }) _selectedFilename: string = '';

  @property({ type: Array }) _filesToUpload: Array<any> = [];

  @property({ type: Boolean }) _showErrorMessage: boolean = false;

  @property({ type: String }) _errorMessage: string = '';

  @property({ type: String }) _errorType: string = '';

  @property({ type: Boolean }) _uploadingFiles: boolean = false;

  private _threadService = new ThreadService();

  static styles: any = [...scrollbarStyles, AdeleUploadFileOverlayStyles];

  stateChanged(_state: { accounts: AccountState }): void {
    this.sources =
      _state.accounts.currentAccount?.allowedSources.map(source => ({
        value: source.id,
        label: source.name,
      })) || [];
  }

  _sourceSelectionChanged() {
    this._showErrorMessage = false;
    const select = this.shadowRoot?.getElementById(
      'sources'
    ) as HTMLSelectElement;
    select.style.border = '2px solid black';
    const selectedValue = select.options[select.selectedIndex].value;
    this._selectedSource = selectedValue.toString();
  }

  _newSourceChanged() {
    this._showErrorMessage = false;
    const newSource = this.shadowRoot?.getElementById(
      'new-source'
    ) as HTMLSelectElement;
    newSource.style.border = '2px solid black';

    this._newSourceName = newSource?.value !== '' ? newSource.value : null;
  }

  _newSourceDescriptionChanged() {
    this._showErrorMessage = false;
    const newSourceDescription = this.shadowRoot?.getElementById(
      'new-source-description'
    ) as HTMLSelectElement;
    newSourceDescription.style.border = '2px solid black';

    this._newSourceDescription =
      newSourceDescription?.value !== '' ? newSourceDescription.value : null;
  }

  _renderNewSourceField() {
    return html`
      <div class="new-source__wrapper">
        <label for="new-source">Enter new source name</label>
        <input id="new-source" type="text" @change=${this._newSourceChanged} />
        ${this._showErrorMessage && this._errorType === 'source-name'
          ? this._renderErrorMessage()
          : nothing}
      </div>
    `;
  }

  _renderNewSourceDescriptionField() {
    return html`
      <div class="new-source__wrapper">
        <label for="new-source-description">Enter new source description</label>
        <input
          id="new-source-description"
          type="text"
          maxlength="100"
          @change=${this._newSourceDescriptionChanged}
        />
      </div>
    `;
  }

  _renderSources() {
    if (this.mode === FileUploadOverlayMode.UPLOAD) return nothing;

    return html`
      <div class="custom-select">
        <label for="sources">Select destination source of the file </label>
        <select id="sources" @change=${this._sourceSelectionChanged}>
          <option selected disabled>Select Source</option>
          ${this.sources
            .filter(s => s?.uploadable)
            .map(
              source => html`
                <option value="${source.value}">${source.label}</option>
              `
            )}
          <option value="other">Create new source</option></select
        >${this._showErrorMessage && this._errorType === 'source-select'
          ? this._renderErrorMessage()
          : nothing}
      </div>
      ${this._selectedSource === 'other'
        ? html`${this._renderNewSourceField()}${this._renderNewSourceDescriptionField()}`
        : nothing}
    `;
  }

  _setFilesToUpload(e) {
    this._filesToUpload = e.detail.value;
  }

  async _uploadFiles(event) {
    event?.preventDefault();
    let filesToUpload: Array<any> = [];

    const vaadinFileUpload: any =
      this.shadowRoot?.querySelector('vaadin-upload');
    const inputField = this.shadowRoot?.getElementById('new-source');
    const sourcesField = this.shadowRoot?.getElementById('sources');

    this._showErrorMessage = false;

    if (vaadinFileUpload?.files?.length === 0) {
      this._errorType = 'file';
      this._showErrorMessage = true;
      this._errorMessage = 'Select files to upload';
      return;
    }

    if (this.mode === FileUploadOverlayMode.SOURCE) {
      this._showErrorMessage = false;

      if (inputField) {
        inputField.style.border = '2px solid black';
      }
      if (sourcesField) {
        sourcesField.style.border = '2px solid black';
      }

      if (this._selectedSource == null) {
        this._errorType = 'source-select';
        this._showErrorMessage = true;
        this._errorMessage = 'Select a source';
        if (sourcesField) {
          sourcesField.style.border = '2px solid red';
        }
        return;
      }

      if (this._selectedSource === 'other' && this._newSourceName == null) {
        this._errorType = 'source-name';
        this._showErrorMessage = true;
        this._errorMessage = 'Enter a source name';
        if (inputField) {
          inputField.style.border = '2px solid red';
        }
        return;
      }
    }

    const formData = new FormData();
    const chatToken =
      store.getState().accounts.currentAccount?.tokens?.chatToken;
    const metadata = await this._createMetadata(chatToken);
    formData.append('metadata', JSON.stringify(metadata));
    vaadinFileUpload.files.forEach(file => {
      formData.append('files', file);
      filesToUpload = [...filesToUpload, file.name];
    });

    try {
      this._uploadingFiles = true;
      let result;

      if (this.mode === FileUploadOverlayMode.UPLOAD) {
        result = await uploadFunctionsClient.vanillaUploadFiles(formData);
      } else {
        result = await uploadFunctionsClient.uploadFiles(formData);
      }

      showNotification(
        'Success',
        'Success',
        result.message || 'Files uploaded successfully!'
      );

      this._resetFields();

      this.dispatchEvent(
        new CustomEvent('files-uploaded', {
          bubbles: true,
          composed: true,
          detail: {
            threadId: this.threadId || metadata.thread_id,
            query: this.threadName || metadata.query,
            files: filesToUpload,
          },
        })
      );
      this._closeOverlay();
    } catch (error) {
      showNotification(
        'Error',
        'Error',
        'There was a problem connecting to server. Please try again.'
      );
    } finally {
      this._uploadingFiles = false;
    }
  }

  async _createMetadata(chatToken) {
    const uuid = this.threadName || uuidv4();
    const threadId = 
      this.threadId ||
      (await mongoFunctionsClient.createThread(uuid))?.thread_id;

    return this.mode === FileUploadOverlayMode.UPLOAD
      ? {
          chatToken,
          query: uuid,
          thread_id: threadId,
        }
      : {
          query: uuid,
          source_id:
            this._selectedSource === 'other' ? null : this._selectedSource,
          source_name: this._newSourceName,
          source_description: this._newSourceDescription,
          client_id: store.getState().accounts.currentAccount?.id.toString(),
          user_id: store.getState().accounts.currentAccount?.userId?.toString(),
          chatToken,
          timestamp: new Date().toISOString(),
          brand: null,
        };
  }

  _renderErrorMessage() {
    return html` <span class="error">${this._errorMessage}</span> `;
  }

  _closeOverlay() {
    this.dispatchEvent(new CustomEvent('close-overlay'));
  }

  _resetFields() {
    const fileUpload = this.shadowRoot?.querySelector('vaadin-upload');
    if (fileUpload) {
      fileUpload.files = [];
      this._filesToUpload = [];
    }
    this._selectedSource = null;
    this._newSourceName = null;
  }

  _getButtonLabel() {
    if (this._uploadingFiles) {
      return this.mode === FileUploadOverlayMode.SOURCE
        ? 'Creating source...'
        : 'Uploading files...';
    }

    return this.mode === FileUploadOverlayMode.SOURCE
      ? 'Create source'
      : 'Upload files';
  }

  render() {
    const accessToken = store.getState().token.value?.accessToken;
    return html`<div class="wrapper">
      <div class="header">
        <nn-button ghost @click=${this._closeOverlay}>
          <nn-icon .svg=${xmark}></nn-icon>
        </nn-button>
      </div>
      <div class="sources">${this._renderSources()}</div>
      <div class="content__wrapper">
        <p>
          Maximum of 5 files only. Total file size must be less than or equal to
          5 MB.<br />
          Only PDF file is accepted.
        </p>

        <div class="upload__wrapper">
          <div class="vaadin-upload__wrapper">
            ${this._showErrorMessage && this._errorType === 'file'
              ? this._renderErrorMessage()
              : nothing}
            <vaadin-upload
              target="https://northstar-prod-app-fastapi.azurewebsites.net/adele/upload"
              headers='{ "Authorization": "Bearer ${accessToken}" }'
              accept="application/pdf,.pdf,.doc,.docx,.txt,.jpg,.jpeg,.png"
              id="upload-drop-enabled"
              .nodrop="${false}"
              no-auto
              .maxFileSize=${5 * 1024 * 1024}
              .maxFiles=${5}
              @files-changed=${this._setFilesToUpload}
            >
            </vaadin-upload>
          </div>
          <div class="footer-actions">
            <vaadin-button
              theme="primary"
              @click=${this._uploadFiles}
              ?disabled=${this._uploadingFiles}
            >
              ${this._getButtonLabel()}
            </vaadin-button>
          </div>
        </div>
      </div>
    </div>`;
  }
}

export { AdeleFileUploadOverlay };
