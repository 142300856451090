import { css } from 'lit';

const AdeleResultStyles = css`
  * {
    box-sizing: border-box;
  }

  :host {
    font-family: 'Poppins', sans-serif;
    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .answers {
    flex: 1;
    min-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: space-between;
    padding: 3rem 6rem 2rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .answers__container {
    width: 100%;
  }

  .images {
    min-width: 319px;
    max-width: 319px;
    height: 70%;
    margin: 0 1rem;
  }

  .page-wrapper {
    border-radius: 24px;

    background: linear-gradient(
      237deg,
      rgba(28, 31, 40, 0.1) 5.65%,
      rgba(28, 31, 40, 0.3) 85.87%,
      rgba(28, 31, 40, 0.5) 97.63%
    );
    box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(18px);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 10px;
  }

  .header {
    display: flex;
    white-space: pre-line;
  }

  .button__content {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  /* Button */
  .search-wrapper {
    position: sticky;
    padding: 0 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
  }

  .search-input {
    margin-left: 0.5rem;
  }

  .search {
    margin: auto;
    width: 100%;
    border-radius: 30px;
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 24px;
  }

  .search__button,
  .search__input {
    outline: transparent;
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .search__button {
    background-color: transparent;
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0;
    left: 16px;
    height: 100%;
    -webkit-tap-highlight-color: transparent;
  }

  .search__icon {
    display: block;
    margin: auto;
    width: 40px;
    height: 80px;
  }

  .search__button:focus-visible .search__icon,
  .search__button:hover .search__icon {
    color: #fff;
  }

  .search__icon-g {
    transform: translate(6px, 6px);
    transform-origin: 12px 12px;
  }

  .search__icon-line2,
  .search__icon-line3 {
    transform-origin: 21px 21px;
  }

  .search__input {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);

    padding-left: 25px;
    padding-right: 350px;
    width: 100%;
    height: 62px;
    font-size: 16px;
    color: #fff;
    backdrop-filter: blur(18px);
    box-shadow: 0px 24px 30px 0px rgba(28, 31, 40, 0.1);
  }

  .search__input::placeholder {
    color: #fff;
    opacity: 60%;
  }

  .search__label {
    position: absolute;
    width: 1px;
    height: 1px;
  }

  .search__send {
    height: 100%;
    display: inline-block;
    cursor: pointer;
  }

  .search__actions {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }

  .search button {
    background-color: transparent;
    border: 0;
    box-sizing: border-box;
    margin: 0;
    margin-left: 10px;
    padding: 0;
  }

  .fine-print p {
    margin: 0 0 1rem 0;
  }

  .plus-circle {
    height: 16px;
    width: 16px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0px solid #434346;
    -webkit-text-fill-color: #f1f1f1;
    -webkit-box-shadow: 0 0 0px 1000px rgba(48, 52, 64, 0.1) inset;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: white;
  }

  .answer__content {
    padding-bottom: 0.5rem;
    font-family: 'Nunito Sans', sans-serif;
    font-optical-sizing: auto;
    padding: 16px 19px;
    border-radius: 16px 16px 16px 4px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(18px);
  }

  .answer__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }

  .answer__header-left {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .answer__header-right {
    font-size: 0.75rem;
  }

  .actions__wrapper {
    display: flex;
  }

  .answer__actions {
    margin-bottom: 1rem;
  }

  .sources h2 {
    margin-top: 0;
  }

  .messages {
    width: 100%;
    display: flex;
    gap: 3rem;
  }

  .message__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .message__container {
    width: 100%;
    max-width: 900px;
  }

  .images__container {
    width: 30%;
    margin-top: 1rem;
    height: min-content;
  }

  .with-border {
    border-top: 1px solid #ffffff50;
  }

  .add-to-collection__button {
    min-width: 200px;
  }

  .recommendation__wrapper {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  nn-icon {
    height: 16px;
    width: 16px;
  }

  .start-tour-button {
    border-radius: 14px;

    background: #ffffff20;
    box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(18px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 50px;
    gap: 10px;
    color: #fff;
    border: 0px;
    font-size: 16px;
    cursor: pointer;
  }

  .search__wrapper {
    display: flex;
    align-items: center;
    font-size: 16px;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(18px);
    box-shadow: 0px 24px 30px 0px rgba(28, 31, 40, 0.1);
    border-radius: 10px;
    padding: 1rem;
  }

  .source-agents__button {
    display: flex;
    height: 32px;
    padding: 0 13px 0 13px;
    gap: 12px;
    align-items: center;
    flex-wrap: nowrap;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    border: 2px solid;
    border-color: transparent;
    position: relative;
    box-sizing: content-box;
    margin: 0 10px;
  }
`;

export { AdeleResultStyles };
