import { css } from 'lit';

export const scrollbarStyles = [
  css`
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      border: 4px solid rgba(255, 255, 255, 0.3);
      background-clip: padding-box;
      border-radius: 25px;
      height: 50px;
    }
  `,
];
