import { css } from '../../base/Base';

const NNTooltipStyles = [
  css`
    slot[name='content'] {
      color: var(--nn-tooltip-color, black);
      display: block;
      padding: 24px 18px;
      border-radius: var(--nn-tooltip-border-radius, 12px);
      max-width: var(--nn-tooltip-max-width, 500px);
      background: rgba(28, 31, 40, 0.8);
      box-shadow: rgba(0, 0, 0, 0.05) 0px 24px 30px 0px;
      backdrop-filter: blur(18px);
    }
    .arrow__graphic {
      fill: var(--nn-tooltip-background-color, black);
    }
  `,
];

export { NNTooltipStyles };
