import { WEBSOCKET_ENDPOINT } from '../../constants/endpoints';
import { getToken } from '../../utils';

let _webSocket: WebSocket;

async function createWebSocketInstance(
  fn,
  statusHandlerFn
): Promise<WebSocket> {
  return new Promise(async resolve => {
    _webSocket = new WebSocket(
      `${WEBSOCKET_ENDPOINT}?token=${await getToken()}`
    );

    _webSocket.addEventListener('open', () => {
      console.info('Connection to WebSocket established');

      statusHandlerFn('READY');
      resolve(_webSocket);
    });

    _webSocket.addEventListener('message', fn);

    _webSocket.addEventListener('close', ({ code, reason }) => {
      console.info('WebSocket closed', code, reason);

      statusHandlerFn('CLOSED');
    });
  });
}

function webSockethandleStatus(webSocketData, state, currentIndex) {
  const currentStep = state.messages[currentIndex]?.step || [];
  if (currentIndex === 0) {
    state = {
      ...state,
      messages: [
        {
          ...state.messages[currentIndex],
          status: webSocketData.content,
          step: [
            ...currentStep,
            {
              label: webSocketData.content,
              category: webSocketData?.category || 'SUMMARIZING',
            },
          ],
        },
      ],
    };
  } else {
    state = {
      ...state,
      messages: state.messages.map((message, index) => {
        if (index === currentIndex) {
          return {
            ...message,
            status: webSocketData.content,
            step: [
              ...currentStep,
              {
                label: webSocketData.content,
                category: webSocketData?.category || 'SUMMARIZING',
              },
            ],
          };
        }

        return message;
      }),
    };
  }

  return state;
}

function webSocketHandleResponsepart(webSocketData, responseAccumulator) {
  return `${responseAccumulator}${webSocketData.content}`;
}

function webSocketHandleCitations(webSocketData, state, currentIndex) {
  if (currentIndex === 0) {
    state = {
      ...state,
      messages: [
        {
          ...state.messages[currentIndex],
          citations: webSocketData.content?.map((content, index) => {
            const count = index + 1;
            return {
              id: index,
              count,
              label: content?.citation || '',
              url: content?.url || '',
            };
          }),
        },
      ],
    };
  } else {
    state = {
      ...state,
      messages: state.messages.map((message, messageIndex) => {
        if (messageIndex === currentIndex) {
          return {
            ...message,
            citations: webSocketData.content?.map((content, index) => {
              const count = index + 1;
              return {
                id: index,
                count,
                label: content?.citation || '',
                url: content?.url || '',
              };
            }),
          };
        }

        return message;
      }),
    };
  }

  return state;
}

function webSocketHandleRecommendation(webSocketData, state, currentIndex) {
  if (currentIndex === 0) {
    state = {
      ...state,
      messages: [
        {
          ...state.messages[currentIndex],
          recommendations: webSocketData.content?.recommendations || [],
        },
      ],
    };
  } else {
    state = {
      ...state,
      messages: state.messages.map((message, index) => {
        if (index === currentIndex) {
          return {
            ...message,
            recommendations: webSocketData.content?.recommendations || [],
          };
        }

        return message;
      }),
    };
  }

  return state;
}

function webSocketHandleImgUrls(webSocketData, state, currentIndex) {
  if (currentIndex === 0) {
    state = {
      ...state,
      messages: [
        {
          ...state.messages[currentIndex],
          image_url: webSocketData.content,
        },
      ],
    };
  } else {
    state = {
      ...state,
      messages: state.messages.map((message, index) => {
        if (index === currentIndex) {
          return {
            ...message,
            image_url: webSocketData.content,
          };
        }

        return message;
      }),
    };
  }

  return state;
}

function webSocketHandleTooltipRefs(webSocketData, state, currentIndex) {
  if (currentIndex === 0) {
    state = {
      ...state,
      messages: [
        {
          ...state.messages[currentIndex],
          tooltip: webSocketData,
        },
      ],
    };
  } else {
    state.messages = state.messages.map((message, index) => {
      if (index === currentIndex) {
        return {
          ...message,
          tooltip: webSocketData,
        };
      }

      return message;
    });
  }

  return state;
}

function webSocketHandleAttachments(webSocketData, state, currentIndex) {
  if (currentIndex === 0) {
    const attachments = state.messages[currentIndex]?.attachment || [];
    state = {
      ...state,
      messages: [
        {
          ...state.messages[currentIndex],
          attachment: [...attachments, webSocketData],
        },
      ],
    };
  } else {
    const attachments = state.messages[currentIndex]?.attachment || [];
    state.messages = state.messages.map((message, index) => {
      if (index === currentIndex) {
        return {
          ...message,
          attachment: [...attachments, webSocketData],
        };
      }

      return message;
    });
  }

  return state;
}

export {
  createWebSocketInstance,
  webSockethandleStatus,
  webSocketHandleResponsepart,
  webSocketHandleCitations,
  webSocketHandleRecommendation,
  webSocketHandleImgUrls,
  webSocketHandleTooltipRefs,
  webSocketHandleAttachments,
};
