/* eslint-disable no-param-reassign */
import { property, state } from 'lit/decorators';
import { classMap } from 'lit-html/directives/class-map';
import {
  CSSResult,
  NNBase,
  html,
  css,
  nothing,
} from '@mch/nn-web-viz/dist/packages/base/Base';
import '@mch/nn-web-viz/dist/nn-tiles';
import '@mch/nn-web-viz/dist/nn-button';
import '@mch/nn-web-viz/dist/nn-icon';
import '@mch/nn-web-viz/dist/nn-overlay';
import '@mch/nn-web-viz/dist/nn-details';
import '@mch/nn-web-viz/dist/nn-link';
import '@mch/nn-web-viz/dist/nn-link-item';
import '@mch/nn-web-viz/dist/nn-accordion';
import '@mch/nn-web-viz/dist/nn-markdown';
import '@mch/nn-web-viz/dist/nn-vertical-image-list';

import { Notification } from '@vaadin/notification';

// Local Component
import '../../adele-collections-sidebar';
import '../../adele-feedback-overlay';
import '../../../components/adele-image-viewer';
import '../../../components/adele-pdf-viewer';
import '../../../components/adele-file-upload-overlay';
import '../../adele-source-agents';
import '../../adele-sources-tooltip';
import '../../adele-search-input';
import './components/AdeleResultMessageHeader';

// Store
import {
  registerStyles,
  css as vaadinCss,
} from '@vaadin/vaadin-themable-mixin';
import { driver } from 'driver.js';
import { TooltipPosition } from '@vaadin/tooltip';
import { sendEvent } from '@mch/nn-web-viz/dist/packages/analytics';
import { connect, store } from '../../../state/store';

// Icons
import { alignLeft } from '../../../assets/icons/alignLeft';
import { bookmark } from '../../../assets/icons/bookmark';
import { copy } from '../../../assets/icons/copy';
import { copyTable as copyTableIcon } from '../../../assets/icons/copyTable';
import { plus } from '../../../assets/icons/plus';
import { messagePen } from '../../../assets/icons/messagePen';
import { download } from '../../../assets/icons/download';
import { stop as stopIcon } from '../../../assets/icons/stop';

// Styles
import { BaseStyles } from '../../../assets/styles/baseStyles';
import { AdeleResultStyles } from './AdeleResultStyles';

import { removeMarkdown } from '../../../utils';
import { adeleClient } from '../../../modules/functions/client';
import { penToSquare } from '../../../assets/icons/penToSquare';
import { bookOpenReader } from '../../../assets/icons/bookOpenReader';
import { magnifyingGlass } from '../../../assets/icons/magnifyingGlass';
import { folderTree } from '../../../assets/icons/folderTree';
import { CATEGORY } from './constant/category';

import { FileUploadOverlayMode } from '../../upload-file-overlay/enums';
import { AccountState } from '../../../state/slices/accounts';

interface User {
  id: number;
  name: string;
}

registerStyles(
  'vaadin-notification-card',
  vaadinCss`
    [part='overlay'] {
    padding: 16px 19px;
    border-radius: 16px;
    background: rgba(28, 31, 40);
    box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(18px);
    color: #ffffff;
    font-weight: 800;
    }
    [part='content'] {
      background: transparent;
    }
  `
);

const getIcon = (category: string) => {
  switch (category.toUpperCase()) {
    case CATEGORY.PROCESSING:
      return bookOpenReader;

    case CATEGORY.DATA_RETRIEVAL:
      return magnifyingGlass;

    case CATEGORY.GENERATION:
      return penToSquare;

    default:
      return folderTree;
  }
};

const copyToClipboard = (e, value) => {
  sendEvent(e);

  navigator.clipboard.writeText(removeMarkdown(value));

  Notification.show('Answer copied to clipboard', {
    position: 'bottom-end',
    duration: 5000,
  });
};

const createTooltip = (
  tooltipFor,
  tooltipText,
  tooltipPosition: TooltipPosition = 'top'
) => html`
  <vaadin-tooltip
    for=${tooltipFor}
    text=${tooltipText}
    position=${tooltipPosition}
  ></vaadin-tooltip>
`;

const renderProvideFeedbackButton = message => {
  const id = `action-provide-feedback-${message.id}`;
  return html`
    <nn-button
      id="${id}"
      nn-analytics-id="provide-feedback-button"
      nn-analytics-description="Provide feedback on the answer"
      class="answer__actions"
      ghost
      slot="invoker"
      @click=${e => sendEvent(e)}
    >
      <nn-icon .svg=${messagePen}></nn-icon>
    </nn-button>
    ${createTooltip(id, 'Provide feedback')}
  `;
};

const renderCopyAnswer = message => {
  const id = `action-copy-answer-${message.id}`;
  return html`
    <nn-button
      ghost
      id="${id}"
      nn-analytics-id="copy-answer-button"
      nn-analytics-description="Copy answer to clipboard"
      class="answer__actions"
      @click=${e => copyToClipboard(e, message.answer)}
    >
      <nn-icon .svg=${copy}></nn-icon>
    </nn-button>
    ${createTooltip(id, 'Copy answer to clipboard')}
  `;
};

const downloadAttachments = (e, message) => {
  sendEvent(e);

  message?.attachment.forEach(attachment => {
    const base64String = attachment.content;

    // Decode base64 string to binary data
    const binaryData = atob(base64String);

    // Convert binary data to a byte array
    const byteArray = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i += 1) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([byteArray], {
      type: attachment.file_type,
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = attachment.file_name;
    document.body.appendChild(link);

    link.click();
  });
};

const renderDownloadAttachments = message => {
  if (message?.attachment == null || message?.attachment?.length === 0)
    return nothing;
  const id = `action-download-${message.id}`;
  return html`<nn-button
      ghost
      id="${id}"
      nn-analytics-id="download-attachments-button"
      nn-analytics-description="Download attachments"
      class="answer__actions"
      @click=${e => downloadAttachments(e, message)}
    >
      <nn-icon .svg=${download}></nn-icon>
    </nn-button>
    ${createTooltip(id, 'Download attachments')} `;
};

const copyTable = async (e, message) => {
  sendEvent(e);

  Notification.show('Server is preparing your file...', {
    position: 'bottom-end',
    duration: 5000,
  });

  const response = await adeleClient.getTable({
    thread_id: message?.thread_id,
    message_id: message?.id,
  });

  const link = document.createElement('a');
  const blobUrl = URL.createObjectURL(response);
  link.href = blobUrl;
  link.download = `${message.question}.zip`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
};

const renderCopyTable = (answer, message) => {
  const containsTable = answer.includes('|--') && answer.includes('--|');
  const id = `action-copy-table-${message.id}`;

  if (!containsTable) return nothing;

  return html`<nn-button
      ghost
      id="${id}"
      nn-analytics-id="copy-table-button"
      nn-analytics-description="Copy table"
      class="answer__actions"
      @click=${e => copyTable(e, message)}
    >
      <nn-icon .svg=${copyTableIcon}></nn-icon>
    </nn-button>
    ${createTooltip(id, 'Download tables')}`;
};

const copyText = e => {
  sendEvent(e, { id: 'adele-result__copy-event', description: 'Copy text' });
};

const HIDE_FOR_DEMO = true;
class AdeleResult extends connect(store)(NNBase) {
  @property({ type: Array }) projects;

  @property({ type: Object }) data;

  @property({ type: Object }) user;

  @property({ type: Array }) steps: Array<any> = [];

  @property({ type: Object }) openCollectionsSidebar;

  @property({ type: Boolean, attribute: 'answer-loading' })
  answerLoading: boolean = false;

  @property({ type: Number }) _selectedImageIndex = -1;

  @property({ type: Number }) _selectedImageMessageIndex = -1;

  @property({ type: Boolean }) showSourcesDropdown = false;

  /**
   * @private
   * Flag to show the sources popup.
   */
  @property({ type: Boolean }) _showSourcesPopup = false;

  /**
   * @private
   * Flag to show the sources popup.
   */
  @property({ type: Boolean }) _showAgentsPopup = false;

  @property({ type: Number }) selectedSource: number | null = null;

  @property({ type: Number }) selectedAgent: number | null = null;

  @property({ type: Object }) _currentAccount: User | null = null;

  @property({ type: Number }) _selectedPdfId: number | null = null;

  @property({ type: Array }) _pdfList: Array<any> | null = null;

  @property({ type: Boolean }) _vanilla: boolean = false;

  @property({ type: Boolean }) _canUpload: boolean = false;

  @property({ type: String }) _searchQuery: string = '';

  @state() private charCount = 0;

  maxCharCount =
    3.7 /* approx charaters per token? */ * 3500; /* max tokens per call? */

  @state() private overCharLimit = this.charCount > this.maxCharCount;

  static styles: CSSResult[] = [
    BaseStyles,
    AdeleResultStyles,

    css`
      @keyframes skeleton-animation {
        0% {
          background-position: 100% 0%;
        }
        70% {
          background-position: -50% 0%;
        }
        100% {
          background-position: -50% 0%;
        }
      }

      .skeleton-container {
        display: flex;
        width: 100%;
        margin-bottom: 2rem;
      }

      .skeleton-container .block {
        position: relative;
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
      .skeleton-container .line {
        border-radius: 10px;
        width: 100%;
        position: absolute;
        height: 12px;
      }
      .skeleton-container .line1 {
        top: 0px;
        width: 100%;
      }
      .skeleton-container .line2 {
        top: 20px;
        width: 80%;
      }

      .skeleton-container .dark .line {
        animation: skeleton-animation 3s infinite linear;
        background: linear-gradient(
          90deg,
          #1c1f2847 10%,
          #1c1f2847 28%,
          #1c1f2827 35%,
          #1c1f2827 42%,
          #1c1f2847 50%
        );
        background-size: 300% 100%;
        will-change: background-position;
        margin-top: 20px;
      }

      .sources {
        position: relative;
        z-index: 999;
      }

      #sourcesButton,
      #agentsButton {
        cursor: pointer;
      }
    `,
  ];

  constructor() {
    super();

    this.openCollectionsSidebar = false;
  }

  connectedCallback(): void {
    super.connectedCallback();

    this.addEventListener('click', this._clickWatcher);
    this.addEventListener('copy', copyText);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    this.removeEventListener('click', this._clickWatcher);
    this.removeEventListener('copy', copyText);
  }

  _clickWatcher(e) {
    const composedPath = e.composedPath();

    if (
      !composedPath.includes(
        this.shadowRoot?.querySelector('#sourcesButton')
      ) &&
      !composedPath.includes(this.shadowRoot?.querySelector('#agentsButton')) &&
      !composedPath.includes(
        this.shadowRoot?.querySelector('adele-source-agents')
      )
    ) {
      this._showSourcesPopup = false;
      this._showAgentsPopup = false;
    }
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('selectedSource')) {
      const sourcesValue = this.shadowRoot?.getElementById('sourcesValue');
      const sourcesButton = this.shadowRoot?.getElementById('sourcesButton');

      if (sourcesValue == null || sourcesButton == null) return;

      sourcesValue.style.display =
        this.selectedSource != null && this.selectedSource !== -1
          ? 'flex'
          : 'none';
      sourcesButton.style.borderColor =
        this.selectedSource != null && this.selectedSource !== -1
          ? '#40ffd7'
          : 'transparent';
    }

    if (changedProps.has('selectedAgent')) {
      const agentsValue = this.shadowRoot?.getElementById('agentsValue');
      const agentsButton = this.shadowRoot?.getElementById('agentsButton');

      if (agentsValue == null || agentsButton == null) return;

      agentsValue.style.display =
        this.selectedAgent != null && this.selectedAgent !== -1
          ? 'flex'
          : 'none';
      agentsButton.style.borderColor =
        this.selectedAgent != null && this.selectedAgent !== -1
          ? '#40ffd7'
          : 'transparent';
    }
  }

  stateChanged(_state: { accounts: AccountState }): void {
    this._vanilla = _state.accounts.currentAccount?.vanilla || false;
    this._canUpload = _state.accounts.currentAccount?.canUpload || false;

    if (this._currentAccount?.id !== _state.accounts.currentAccount?.id) {
      this._currentAccount = _state.accounts.currentAccount;
    }

    const selectedSourceChanged =
      this.selectedSource !== _state.accounts?.selectedSource || null;
    const selectedAgentChanged =
      this.selectedAgent !== _state.accounts?.selectedAgent || null;

    if (selectedSourceChanged && _state.accounts.selectedSource !== null) {
      this.selectedSource = _state.accounts.selectedSource;
    }

    if (selectedAgentChanged && _state.accounts.selectedAgent !== null) {
      this.selectedAgent = _state.accounts.selectedAgent;
    }
  }

  _feedbackOverlay(message) {
    return html`<nn-overlay class="overlay">
      ${renderProvideFeedbackButton(message)}
      <adele-feedback-overlay
        slot="content"
        question=${message.question || ''}
        answer=${message.answer || ''}
        thread-id=${this.data?.thread?.id}
      ></adele-feedback-overlay>
    </nn-overlay>`;
  }

  _toggleCollectionsSidebar(e) {
    sendEvent(e);

    this.openCollectionsSidebar = !this.openCollectionsSidebar;
  }

  _closeCollectionsSidebar() {
    this.openCollectionsSidebar = false;
  }

  _renderSpinner() {
    const answerBubble = this.shadowRoot?.getElementById('answer__content');
    if (answerBubble) {
      answerBubble.style.animation = 'skeleton-animation 3s infinite linear';
      answerBubble.style.background =
        'linear-gradient(90deg, #1c1f2847 10%, #1c1f2847 28%, #1c1f2827 35%, #1c1f2827 42%, #1c1f2847 50%)';
      answerBubble.style.backgroundSize = '300% 100%';
      answerBubble.style.willChange = 'background-position';
    }
  }

  _renderProgressDetails(index) {
    const isLatestMessage = this.data.messages.length - 1 === index;
    const steps = this.data?.messages?.[this.data?.messages?.length - 1]?.step;
    /**
     * If the index is 0, then we are at the first thread
     */
    if (index === 0) {
      return html`
        ${this._renderStepDetails(
          (this.data?.messages?.length === 1 || this?.data?.messages == null) &&
            this.answerLoading,
          steps
        )}
      `;
    }

    return this._renderStepDetails(
      isLatestMessage && this.answerLoading,
      steps
    );
  }

  _renderAddToCollection() {
    if (this._vanilla) return nothing;

    return html`
      <nn-button
        id="addToCollection"
        nn-analytics-id="add-to-collection-button"
        nn-analytics-description="Open the add to collection sidebar"
        class="add-to-collection__button"
        @click=${this._toggleCollectionsSidebar}
        ?disabled=${!this.data?.id}
        ghost
        ><div class="button__content">
          <nn-icon .svg=${bookmark}></nn-icon><span>ADD TO COLLECTION</span>
        </div></nn-button
      >
    `;
  }

  _renderMessageHeader(message = {}, index = 0) {
    return html`
      <div class="message__header">
        <adele-result-message-header
          .data=${{ ...message, title: this.data.title, index }}
        ></adele-result-message-header>
        ${index === 0 ? this._renderAddToCollection() : nothing}
      </div>
    `;
  }

  _selectedImageChanged(messageIndex, { detail: { index } }) {
    this._selectedImageMessageIndex = messageIndex;
    this._selectedImageIndex = index;
  }

  _renderStartTourButton() {
    if (this._vanilla) return nothing;

    return html`
      <div class="start-tour">
        <button
          nn-analytics-id="start-tour-button"
          nn-analytics-description="Start the tour"
          class="start-tour-button"
          @click=${this._startTour}
        >
          Start Tour
        </button>
      </div>
    `;
  }

  _renderSelectedSourcesAndAgents(index) {
    if (this._vanilla) return nothing;

    const selectedSourceId = this.data?.messages[index]?.source_id || null;
    const selectedAgentId = this.data?.messages[index]?.agent_id || null;

    return html`
      <div class="answer__header-right">
        <div>
          Source:
          ${store
            .getState()
            .accounts.currentAccount?.allowedSources.find(
              source => source.id === selectedSourceId
            )?.name || 'No source'}
        </div>
        <div>
          Agent:
          ${store
            .getState()
            .accounts.currentAccount?.allowedAgents.find(
              agent => agent.id === selectedAgentId
            )?.name || 'No agent'}
        </div>
      </div>
    `;
  }

  _renderAnswer(message, index, answer) {
    const isLastItem = (this.data?.messages?.length || 1) - 1 === index;

    // Schedule the scroll to the bottom after the DOM update
    if (isLastItem) {
      requestAnimationFrame(() => {
        this.scrollToLatestAnswer();
      });
    }

    return html`
      <div class="answer__container">
        <div class="answer__header">
          <div class="answer__header-left">
            <nn-icon .svg=${alignLeft}></nn-icon>
            <h2>Answer</h2>
            ${index === 0 ? this._renderStartTourButton() : nothing}
          </div>
          ${this._renderSelectedSourcesAndAgents(index)}
        </div>
        <div id="answer__content-${index}" class="answer__content">
          <nn-markdown
            markdown="${answer}"
            .styles=${[
              css`
                a {
                  color: #f82c91;
                }
              `,
            ]}
          ></nn-markdown>
        </div>
        ${this.answerLoading && isLastItem
          ? this._renderSpinner()
          : html` <div class="actions__wrapper">
              ${renderCopyAnswer(message)} ${this._feedbackOverlay(message)}
              ${renderCopyTable(answer, message)}
              ${renderDownloadAttachments(message)}
            </div>`}
      </div>
    `;
  }

  scrollToLatestAnswer() {
    const container = this.shadowRoot?.querySelector('.answers__container');
    if (container) {
      const elements = container.querySelectorAll('.answer__container');
      const lastElement = elements[elements.length - 1];
      if (lastElement) {
        lastElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  }

  _resetPdfSelections() {
    this._selectedPdfId = null;
    this._pdfList = null;
  }

  _setPdfViewerData({ detail: { data } }, sources) {
    this._pdfList = sources;
    this._selectedPdfId = data?.id;
  }

  _renderSources(sources) {
    if (sources?.length === 0 || sources == null) return nothing;

    return html`
      <div id="sources" class="sources">
        <h2>Sources</h2>
        <div>
          <nn-link
            nn-analytics-id="sources-link"
            nn-analytics-description="Open sources"
            @click=${e => sendEvent(e)}
            @mouseover=${e => sendEvent(e)}
            style="display:block; max-width:600px; overflow-wrap: break-word;"
            .data=${sources}
            @link-item-clicked=${e => this._setPdfViewerData(e, sources)}
            show-pdf-popup
          ></nn-link>
        </div>
      </div>
    `;
  }

  _setFollowUpQuestion(e, question) {
    sendEvent(e);

    const inputField = this.shadowRoot?.querySelector(
      'adele-search-input'
    ) as any;

    inputField.modelValue = question;
  }

  _renderRecommendations(recommendations) {
    if (this._vanilla) return nothing;

    if (recommendations?.length === 0 || recommendations == null)
      return nothing;

    return html`
      <div class="recommended">
        <h2>Recommended Content</h2>
        <div>
          ${recommendations.map(
            recommendation => html`
              <div
                nn-analytics-id="adele-result__ask-question-event"
                nn-analytics-description="Ask another question based on recommendation"
                class="recommendation__wrapper"
                @click=${e => this._setFollowUpQuestion(e, recommendation)}
                @keyup=${e => this._setFollowUpQuestion(e, recommendation)}
              >
                <div>${recommendation}</div>
                <nn-button
                  ghost
                  @click=${e => this._setFollowUpQuestion(e, recommendation)}
                  ><nn-icon .svg=${plus}></nn-icon
                ></nn-button>
              </div>
            `
          )}
        </div>
      </div>
    `;
  }

  _renderStepDetails(loading: boolean, steps) {
    if (this._vanilla) return nothing;

    const getSummarySubtitle = (step: number, loadingSteps: boolean) => {
      if (!loadingSteps) return 'All steps completed';

      if (step > 1) return `${step - 1} steps completed`;

      return '';
    };

    return html`
      <div>
        <nn-details
          .openedTitle=${!loading
            ? 'ADELe finished the tasks'
            : 'ADELe is working on it'}
          .closedTitle=${loading
            ? ' Tasks are in progress'
            : ' All tasks were completed'}
          .closedSubtitle=${getSummarySubtitle(
            steps?.length > 0 ? steps?.length : 1,
            loading
          )}
          .loading=${loading || false}
          .opened=${loading || false}
        >
          ${(steps || []).map(
            step => html`
              <div
                slot="steps"
                class="details__item"
                style="display: flex; align-items: center; gap: 0.5rem;"
              >
                <nn-icon
                  style="height: 1rem; width: 1rem; margin: 0 0.5rem;"
                  .svg=${getIcon(step?.category || CATEGORY.PROCESSING)}
                ></nn-icon>
                <div>${step.label}</div>
              </div>
            `
          )}
        </nn-details>
      </div>
    `;
  }

  _resetImageViewerSelections() {
    this._selectedImageIndex = -1;
  }

  _formSubmit(event) {
    event?.preventDefault();

    this._performSearch();
  }

  _performSearch() {
    if (this._searchQuery != null && this._searchQuery !== '') {
      this.dispatchEvent(
        new CustomEvent('search-query-changed', {
          bubbles: true,
          composed: true,
          detail: {
            data: this._searchQuery,
          },
        })
      );
    }

    const adeleSearchInput: any =
      this.shadowRoot?.querySelector('adele-search-input');
    if (adeleSearchInput != null) {
      adeleSearchInput.clear();
    }
  }

  _toggleSourcesDropdown(e): void {
    sendEvent(e);

    this._showSourcesPopup = !this._showSourcesPopup;
    this._showAgentsPopup = false;
  }

  _toggleAgentsDropdown(e): void {
    sendEvent(e);

    this._showAgentsPopup = !this._showAgentsPopup;
    this._showSourcesPopup = false;
  }

  _setSelectedSource({ detail: { data } }) {
    this.selectedSource = data;
  }

  _setSelectedAgent({ detail: { data } }) {
    this.selectedAgent = data;
  }

  renderSourcesAndAgentsPopup() {
    if (!this._showSourcesPopup && !this._showAgentsPopup) return nothing;

    return html`
      <adele-source-agents
        style="--source-agents-absolute-right: 110px; --source-agents-absolute-bottom: 120px;"
        .selectedSource=${this.selectedSource}
        .selectedAgent=${this.selectedAgent}
        .currentAccount=${this._currentAccount}
        .display=${this._showSourcesPopup ? 'sources' : 'agents'}
        @source-changed=${this._setSelectedSource}
        @agent-changed=${this._setSelectedAgent}
      ></adele-source-agents>
    `;
  }

  _dispatchStopSearch(e) {
    sendEvent(e);

    this.dispatchEvent(new CustomEvent('stop-search', { bubbles: true }));
  }

  _renderStopSearch() {
    return html`<nn-button
      ghost
      nn-analytics-id="stop-search-button"
      nn-analytics-description="Stop the search"
      @click=${this._dispatchStopSearch}
      style="border: 1px solid #ffffff10"
    >
      <nn-icon style="fill: red" .svg=${stopIcon}></nn-icon>
    </nn-button>`;
  }

  render() {
    if (this._selectedPdfId != null) {
      return this._renderPdfViewer();
    }

    if (this._selectedImageIndex !== -1) {
      return this._renderImageViewer();
    }

    return this._renderResult();
  }

  _renderPdfViewer() {
    if (this._pdfList != null) {
      return html`<adele-pdf-viewer
        .data=${this._pdfList}
        .selected=${this._selectedPdfId || 0}
        @back-clicked=${this._resetPdfSelections}
      ></adele-pdf-viewer>`;
    }

    return nothing;
  }

  _renderImageViewer() {
    return html`
      <adele-image-viewer
        .data=${this.data?.messages[
          this._selectedImageMessageIndex
        ]?.image_url.map(img => ({
          imageUrl: img.url,
          alt: img.desc,
          imageCitation: img.citation,
        })) || []}
        .selected=${this._selectedImageIndex}
        @back-clicked=${this._resetImageViewerSelections}
        @source-item-clicked=${e =>
          this._setPdfViewerData(
            e,
            this.data?.messages[this._selectedImageMessageIndex].citations
          )}
      ></adele-image-viewer>
    `;
  }

  _renderResult() {
    return html`
      <div class="page-wrapper">
        <div class="container flex">
          <div class="answers">
            <div class="answers__container">${this._renderMessages()}</div>
          </div>
          ${this._renderSearchInput()}
        </div>
      </div>
      ${this.openCollectionsSidebar
        ? html`<adele-collections-sidebar
            .threadId=${this.data.id}
            @close-collections-sidebar=${this._closeCollectionsSidebar}
          ></adele-collections-sidebar>`
        : nothing}
    `;
  }

  _renderMessages() {
    if (this.data?.messages == null) {
      return html`
        <div class="messages">
          <div class="message__container">${this._renderMessageHeader()}</div>
        </div>
        ${this._renderStepDetails(true, [])}
      `;
    }

    return this.data?.messages?.map((message, index) => {
      const isLastItem = this.data.messages.length - 1 === index;
      const formattedAnswer = (message.answer || '').replace(
        /\*\*(.*?)\*\*/g,
        '<strong>$1</strong>'
      );

      return html`<div
        class="messages ${classMap({
          'with-border': index !== 0,
        })} "
      >
        <div class="message__container">
          ${this._renderMessageHeader(message, index)}
          ${this._renderProgressDetails(index)}
          ${this._renderAnswer(message, index, formattedAnswer)}
          ${!isLastItem || (isLastItem && !this.answerLoading)
            ? this._renderSources(message.citations)
            : nothing}
          ${!isLastItem || (isLastItem && !this.answerLoading)
            ? this._renderRecommendations(message.recommendations)
            : nothing}
        </div>
        ${message?.image_url?.length > 0
          ? html`<div class="images__container" id="image-viewer">
              <p style="text-align:center;width:100% ">
                Possible Related Images
              </p>
              <nn-vertical-image-list
                .data=${(message?.image_url || []).map(
                  ({ url, desc, citation }) => ({
                    imageUrl: url,
                    alt: desc,
                    imageCitation: citation,
                  })
                )}
                display-count="4"
                @selected-image-changed=${e =>
                  this._selectedImageChanged(index, e)}
              ></nn-vertical-image-list>
            </div>`
          : nothing}
      </div>`;
    });
  }

  _startTour(e) {
    sendEvent(e);

    const firstSourcesTooltip = this.shadowRoot?.querySelector('#sources');
    const answerSourcesTooltip = this.shadowRoot
      ?.querySelector('nn-markdown')
      ?.shadowRoot?.querySelector('sources-tooltip');
    const imageViewerTooltip = this.shadowRoot?.querySelector('#image-viewer');
    const addToCollectionTooltip =
      this.shadowRoot?.querySelector('#addToCollection');
    const applyCustomStyles = popover => {
      popover.wrapper.classList.add('driver-popover-custom');
      // You can also apply styles directly if needed
      popover.wrapper.style.borderRadius = '24px';
      popover.wrapper.style.background =
        'linear-gradient(237deg, rgba(28, 31, 40, 0.1) 5.65%, rgba(28, 31, 40, 0.3) 85.87%, rgba(28, 31, 40, 0.5) 97.63%)';
      popover.wrapper.style.boxShadow = '0px 24px 30px 0px rgba(0, 0, 0, 0.05)';
    };
    const driverObj = driver({
      popoverClass: 'driver-popover-custom', // Use your custom class
      stagePadding: 10,
      stageRadius: 24,
      showProgress: true,
      overlayColor: 'rgb(0, 0, 0)',
      nextBtnText: 'Next',
      prevBtnText: 'Back',
      doneBtnText: 'Done',
      onPopoverRender: popover => {
        applyCustomStyles(popover);
      },
      steps: [
        {
          element: answerSourcesTooltip || undefined,
          popover: {
            title: 'Want to know the source?',
            description:
              'Hover over the citation number to view the PDF that helped generate the response.',

            side: 'top',
            align: 'center',
          },
        },
        {
          element: firstSourcesTooltip || undefined,
          popover: {
            title: 'Want to know the source?',
            description:
              'Hover over the citation number to view the PDF that helped generate the response.',

            side: 'top',
            align: 'center',
          },
        },
        {
          element: imageViewerTooltip || undefined,
          popover: {
            title: 'Are you a visual learner?',
            description:
              'Explore possible related images to the response. Expand the image by clicking on the thumbnail.',

            side: 'top',
            align: 'center',
          },
        },
        {
          element: addToCollectionTooltip || undefined,
          popover: {
            title: 'Want to save a response?',
            description:
              'Start saving useful questions and answers by selecting add to collections.',

            side: 'top',
            align: 'center',
          },
        },
      ],
    });

    driverObj.drive();
  }

  _renderSourcesAndAgentsButton() {
    if (this._vanilla) return nothing;

    return html`${this._renderAgentsButton()} ${this._renderSourcesButton()}`;
  }

  _renderAgentsButton() {
    return html`
      <div
        id="agentsButton"
        nn-analytics-id="agents-button"
        nn-analytics-description="${!this._showAgentsPopup
          ? 'Open'
          : 'Close'} agents popup"
        class="main-container source-agents__button"
        @click=${this._toggleAgentsDropdown}
        @keyup=${this._toggleAgentsDropdown}
      >
        <span
          style="height:16px;flex-shrink:0;flex-basis:auto;font-family:Nunito Sans, var(--default-font-family);font-size:12px;font-weight:700;line-height:16px;color:#ffffff;letter-spacing:-0.24px;position:relative;text-align:left;white-space:nowrap"
          >AGENTS</span
        >
        <div
          style="display:none;width:35px;padding:4px 10px 4px 10px;gap:8px;justify-content:center;align-items:center;flex-shrink:0;flex-wrap:nowrap;border-radius:99px;border:1px solid #40ffd7;position:relative;z-index:1"
          id="agentsValue"
        >
          <span
            style="height:14px;flex-shrink:0;flex-basis:auto;font-family:Nunito Sans, var(--default-font-family);font-size:12px;font-weight:600;line-height:14px;color:#ffffff;position:relative;text-align:left;white-space:nowrap;z-index:2"
            >+1</span
          >
        </div>
      </div>
    `;
  }

  _renderSourcesButton() {
    return html`<div
      id="sourcesButton"
      nn-analytics-id="sources-button"
      nn-analytics-description="${!this._showSourcesPopup
        ? 'Open'
        : 'Close'} sources popup"
      class="main-container source-agents__button"
      @click=${this._toggleSourcesDropdown}
      @keyup=${this._toggleSourcesDropdown}
    >
      <span
        style="height:16px;flex-shrink:0;flex-basis:auto;font-family:Nunito Sans, var(--default-font-family);font-size:12px;font-weight:700;line-height:16px;color:#ffffff;letter-spacing:-0.24px;position:relative;text-align:left;white-space:nowrap"
        >SOURCES</span
      >
      <div
        style="display:none;width:35px;padding:4px 10px 4px 10px;gap:8px;justify-content:center;align-items:center;flex-shrink:0;flex-wrap:nowrap;border-radius:99px;border:1px solid #40ffd7;position:relative;z-index:1"
        id="sourcesValue"
      >
        <span
          style="height:14px;flex-shrink:0;flex-basis:auto;font-family:Nunito Sans, var(--default-font-family);font-size:12px;font-weight:600;line-height:14px;color:#ffffff;position:relative;text-align:left;white-space:nowrap;z-index:2"
          >+1</span
        >
      </div>
    </div>`;
  }

  _renderSearchActions() {
    return html`
      <div class="search__actions">
        ${this.answerLoading
          ? this._renderStopSearch()
          : html`
              <button
                ?disabled=${this.overCharLimit}
                nn-analytics-id="adele-thread__ask-question-event"
                nn-analytics-description="Ask another question to an existing thread"
                @click=${e => sendEvent(e)}
                class="search__send"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="39"
                  height="38"
                  viewBox="0 0 39 38"
                  fill="none"
                >
                  <rect
                    x="0.636719"
                    y="0.211914"
                    width="37.9657"
                    height="37.4233"
                    rx="10.0885"
                    fill="#ffffff10"
                  />
                  <path
                    d="M18.449 18.9245L13.9149 18.9245M28.467 18.4099C28.5634 18.4568 28.6447 18.53 28.7016 18.621C28.7584 18.7119 28.7885 18.8171 28.7884 18.9244C28.7883 19.0316 28.7581 19.1367 28.7011 19.2276C28.6441 19.3185 28.5627 19.3915 28.4662 19.4384L11.196 27.7553C11.0927 27.805 10.9769 27.8225 10.8636 27.8056C10.7502 27.7887 10.6446 27.7381 10.5604 27.6603C10.4761 27.5826 10.4172 27.4814 10.3912 27.3698C10.3652 27.2582 10.3734 27.1413 10.4146 27.0344L13.4547 19.1293C13.5054 18.9975 13.5054 18.8515 13.4547 18.7197L10.4154 10.8138C10.3745 10.707 10.3666 10.5904 10.3927 10.4791C10.4187 10.3677 10.4776 10.2668 10.5617 10.1892C10.6457 10.1117 10.7511 10.0612 10.8641 10.0441C10.9772 10.0271 11.0928 10.0443 11.196 10.0937L28.467 18.4099Z"
                    stroke=${this.overCharLimit ? '#666' : 'white'}
                    stroke-width="3.39973"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            `}
        ${this._renderSourcesAndAgentsButton()}
      </div>
    `;
  }

  _renderCharCount() {
    let charCountStyle = '';

    if (this.charCount > this.maxCharCount) {
      charCountStyle = 'color: #ff4500';
    } else if (this.charCount > this.maxCharCount * 0.9) {
      charCountStyle = 'color: var(--lumo-warning-color)';
    }

    return html`
      <div style="align-self: flex-start; ${charCountStyle}">
        Character count: ${this.charCount} /
        ${this.maxCharCount}${this.overCharLimit
          ? `. Please reduce your prompt.`
          : null}
      </div>
    `;
  }

  _submitSearch(e) {
    sendEvent(e);
    this._searchQuery = e.detail;

    sendEvent(e);

    this._performSearch();
  }

  _searchInputChanged(e) {
    this.charCount = e.detail.length;
    this.overCharLimit = this.charCount > this.maxCharCount;

    const inputField = this.shadowRoot?.querySelector(
      'adele-search-input'
    ) as any;

    inputField.overCharLimit = this.overCharLimit;

    this._searchQuery = e.detail;
  }

  _filesUploaded({ detail }) {
    this.dispatchEvent(new CustomEvent('files-uploaded', { detail }));
  }

  _renderFileUploadOverlay() {
    if (HIDE_FOR_DEMO) return nothing;
    if (!this._canUpload) return nothing;

    return html`
      <nn-overlay
        .config=${{
          hidesOnOutsideClick: true,
        }}
        ><button
          nn-analytics-id="upload-button"
          nn-analytics-description="Upload file button"
          @click=${e => {
            e.preventDefault();

            sendEvent(e);
          }}
          class="search__upload"
          style="margin-right: 10px;"
          slot="invoker"
        >
          <svg
            width="39"
            height="38"
            viewBox="0 0 39 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x=".844"
              y=".212"
              width="37.966"
              height="37.423"
              rx="10.088"
              fill="#ffffff10"
            />
            <g clip-path="url(#a)">
              <path
                d="M21.42 23.94c0 .6-.483 1.084-1.083 1.084s-1.084-.483-1.084-1.084V11.384l-4.289 4.289a1.083 1.083 0 0 1-1.53-1.53l6.135-6.141a1.08 1.08 0 0 1 1.531 0l6.145 6.14a1.083 1.083 0 0 1-1.53 1.53l-4.29-4.288V23.94h-.005Zm1.445-.361v-2.167h6.14a2.892 2.892 0 0 1 2.89 2.89v3.611a2.892 2.892 0 0 1-2.89 2.89H11.669a2.892 2.892 0 0 1-2.89-2.89v-3.612a2.892 2.892 0 0 1 2.89-2.89h6.14v2.168h-6.14a.724.724 0 0 0-.722.722v3.612c0 .398.325.723.722.723h17.338a.725.725 0 0 0 .722-.723v-3.612a.725.725 0 0 0-.722-.722h-6.14Zm3.251 2.528a1.084 1.084 0 1 1 2.168 0 1.084 1.084 0 0 1-2.168 0Z"
                fill="#fff"
              />
            </g>
            <defs>
              <clipPath id="a">
                <path fill="#fff" d="M8.778 7.686h23.117v23.117H8.778z" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <adele-file-upload-overlay
          thread-id=${this.data?.id}
          thread-name=${this.data?.title}
          slot="content"
          mode=${FileUploadOverlayMode.UPLOAD}
          @files-uploaded=${this._filesUploaded}
        ></adele-file-upload-overlay>
      </nn-overlay>
    `;
  }

  _renderSearchInput() {
    return html`
      <div class="search-wrapper">
        ${this.renderSourcesAndAgentsPopup()}

        <form class="search" @submit=${e => this._formSubmit(e)}>
          <label class="search__label" for="search"></label>
          <div class="search__wrapper">
            ${this._renderFileUploadOverlay()}
            <adele-search-input
              nn-analytics-id="adele-result__ask-question-event"
              nn-analytics-description="Ask another question to an existing thread"
              class="search-input"
              @search=${this.overCharLimit ? null : this._submitSearch}
              @model-value-changed=${this._searchInputChanged}
            ></adele-search-input>
            ${this._renderSearchActions()}
          </div>
        </form>
        ${this._renderCharCount()}
        <div class="fine-print">
          <p>
            ADELe can make mistakes. Consider checking important information.
          </p>
        </div>
      </div>
    `;
  }
}

export { AdeleResult };
