import { svg } from 'lit';

const folderTree = svg`<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_6_3731)">
<path d="M1.5 0.75C1.5 0.334375 1.16562 0 0.75 0C0.334375 0 0 0.334375 0 0.75V4.25V12.25C0 13.2156 0.784375 14 1.75 14H8V12.5H1.75C1.6125 12.5 1.5 12.3875 1.5 12.25V5H8V3.5H1.5V0.75ZM10.5 5.5V1.5H11.8781L12.4094 2.03125C12.7094 2.33125 13.1156 2.5 13.5406 2.5H16.5V5.5H10.5ZM9 1V6C9 6.55312 9.44687 7 10 7H17C17.5531 7 18 6.55312 18 6V2C18 1.44687 17.5531 1 17 1H13.5406C13.5156 1 13.4875 0.990625 13.4688 0.971875L12.7937 0.29375C12.6062 0.10625 12.3531 0 12.0875 0H10C9.44687 0 9 0.446875 9 1ZM10.5 14.5V10.5H11.8781L12.4094 11.0312C12.7094 11.3313 13.1156 11.5 13.5406 11.5H16.5V14.5H10.5ZM9 10V15C9 15.5531 9.44687 16 10 16H17C17.5531 16 18 15.5531 18 15V11C18 10.4469 17.5531 10 17 10H13.5406C13.5156 10 13.4875 9.99063 13.4688 9.97188L12.7906 9.29375C12.6031 9.10625 12.35 9 12.0844 9H10C9.44687 9 9 9.44687 9 10Z" fill="#C5C8E2"/>
</g>
<defs>
<clipPath id="clip0_6_3731">
<rect width="18" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
`;

export { folderTree };
