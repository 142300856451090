import { PropertyDeclarations, nothing } from 'lit';
import { NNBase, html, css } from '../../base/Base';

import { Link } from './types';

class NNLink extends NNBase {
  static properties: PropertyDeclarations = {
    ...super.properties,
    data: { type: Array },
    showPdfPopup: { type: Boolean, attribute: 'show-pdf-popup' },
  };

  declare data: Array<Link>;

  declare showPdfPopup: boolean;

  constructor() {
    super();

    this.showPdfPopup = false;
  }

  static styles: any = [
    css`
      * {
        box-sizing: border-box;
      }

      :host {
        display: flex;
      }

      .link-item {
        padding: 4px 0;
      }

      .link-item__wrapper {
        width: 100%;
        white-space: normal;
        word-break: break-word;
        max-width: 800px;
      }
    `,
  ];

  render() {
    if (this.data == null) return nothing;

    return html`<div class="link-item__wrapper">
      ${this.data.map(
        d =>
          html`
            <nn-link-item
              class="link-item"
              .data=${d}
              ?show-pdf-popup=${this.showPdfPopup}
            ></nn-link-item>
          `
      )}
    </div>`;
  }
}

export { NNLink };
