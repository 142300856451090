import { PropertyDeclarations, nothing } from 'lit';
import { NNBase, html, css } from '../../base/Base';

class NNVerticalImageList extends NNBase {
  static properties: PropertyDeclarations = {
    ...super.properties,
    data: { type: Object },
    displayCount: { type: Number, attribute: 'display-count' },
  };

  declare data: Array<any>;

  declare displayCount: number;

  constructor() {
    super();

    this.displayCount = 4;
  }

  static styles: any = [
    css`
      :host {
        display: flex;
        width: 100%;
        height: 100%;
      }

      img {
        overflow: hidden;
        width: 200px;
        height: 200px;
        border-radius: 8px;
      }

      .container {
        margin: 0 auto;
        width: 100%;
      }

      .image-list {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        gap: 8px;
      }

      .image-container {
        cursor: pointer;
        background: white;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
      }

      ::-webkit-scrollbar {
        width: 20px;
      }

      ::-webkit-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: rgba(28, 31, 40, 0.5);
      }

      .footer-actions {
        display: flex;
        justify-content: flex-end;
      }

      nn-button {
        width: 100px;
        font-family: Nunito Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.02em;
        text-align: left;
      }
    `,
  ];

  _viewMoreClicked() {
    this.displayCount = this.data.length;
  }

  _dispatchSelectedImageChanged(index) {
    const event = new CustomEvent('selected-image-changed', {
      detail: {
        index,
      },
      bubbles: true,
      composed: true,
    });

    this.dispatchEvent(event);
  }

  render() {
    if (this.data == null) return nothing;

    return html`<div class="container">
      <div class="image-list">
        ${this.data.slice(0, this.displayCount).map(
          (data, index) => html`
            <div
              class="image-container"
              @click=${() => this._dispatchSelectedImageChanged(index)}
              @keyup=${() => this._dispatchSelectedImageChanged(index)}
            >
              <img src=${data.imageUrl} alt=${data.alt} />
            </div>
          `
        )}
      </div>
      <div class="footer-actions">
        ${this.data.length > this.displayCount
          ? html`<nn-button
              ghost
              text-transform-none
              @click=${this._viewMoreClicked}
              >View more</nn-button
            >`
          : nothing}
      </div>
    </div>`;
  }
}

export { NNVerticalImageList };
