/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { mongoFunctionsClient } from '../modules/functions/client';

export default class MongoService {
  public async createThread(name: string) {
    const result: { status: string; thread_id: string } =
      await mongoFunctionsClient.createThread(name);

    return result.thread_id;
  }

  public async postMessages({
    question,
    answer,
    source,
    agent,
    thread,
    threadId,
  }) {
    const sourceId = source;
    const agentId = agent;
    const { recommendations } = thread.messages[thread.messages.length - 1];
    const { citations } = thread.messages[thread.messages.length - 1];
    const imageUrl = thread.messages[thread.messages.length - 1].image_url;
    const { tooltip } = thread.messages[thread.messages.length - 1];
    const { step } = thread.messages[thread.messages.length - 1];
    const { attachment } = thread.messages[thread.messages.length - 1];

    const result = await mongoFunctionsClient.postMessages({
      question,
      answer,
      sourceId,
      agentId,
      recommendations,
      citations,
      imageUrl,
      tooltip,
      step,
      attachment,
      threadId,
    });

    return result;
  }
}
