import { svg } from 'lit';

const messagePen = svg`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_6_6364)">
<path d="M6.5 13C6.5 12.1719 5.82812 11.5 5 11.5H2C1.725 11.5 1.5 11.275 1.5 11V2C1.5 1.725 1.725 1.5 2 1.5H14C14.275 1.5 14.5 1.725 14.5 2V11C14.5 11.275 14.275 11.5 14 11.5H9.66562C9.34062 11.5 9.025 11.6062 8.76562 11.8L6.5 13.5V13ZM6.49375 15.3813L6.5 15.375L9.66562 13H14C15.1031 13 16 12.1031 16 11V2C16 0.896875 15.1031 0 14 0H2C0.896875 0 0 0.896875 0 2V11C0 12.1031 0.896875 13 2 13H3.5H5V14.5V14.625V14.6344V14.8344V15.5C5 15.6906 5.10625 15.8625 5.275 15.9469C5.44375 16.0312 5.64688 16.0125 5.8 15.9L6.33437 15.5L6.49375 15.3813ZM10.65 3.84375C10.1906 3.38438 9.44375 3.38438 8.98438 3.84375L8.51875 4.3125L10.1844 5.97813L10.65 5.5125C11.1094 5.05312 11.1094 4.30625 10.65 3.84687V3.84375ZM5.56563 7.26562C5.4375 7.39375 5.34687 7.55312 5.30312 7.73125L5.01562 8.875C4.97188 9.04688 5.02187 9.225 5.14687 9.35C5.27187 9.475 5.45 9.525 5.62187 9.48125L6.76562 9.19375C6.94063 9.15 7.10312 9.05937 7.23125 8.93125L9.47812 6.68437L7.8125 5.01875L5.56563 7.26562Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_6_6364">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
`;

export { messagePen };
