import { property } from 'lit/decorators';
import { LitElement, css, html, nothing } from 'lit';

import '@mch/nn-web-viz/dist/nn-sources-tooltip';
import '@mch/nn-web-viz/dist/nn-spinner';

import { connect, store } from '../../state/store';
import { ThreadState } from '../../state/slices/thread';

class AdeleSourcesTooltip extends connect(store)(LitElement) {
  @property({ type: Number, attribute: 'tooltip-source-number' })
  tooltipSourceNumber: number = 0;

  @property({ type: String, attribute: 'tooltip-ref' }) tooltipRef: string = '';

  @property({ type: Object }) data: any = null;

  static styles: any = [
    css`
      :host {
        position: relative;
      }
      :host::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(4px);
      }
    `,
  ];

  stateChanged(_state: { thread: ThreadState }): void {
    if (_state.thread?.current != null) {
      const tooltips = _state.thread?.current?.messages.reduce(
        (acc, curr) => ({ ...acc, ...curr.tooltip }),
        {}
      );

      this.data = tooltips[this.tooltipRef];
    }
  }

  mouseOver() {
    this.style.setProperty('z-index', '999');
  }

  mouseOut() {
    this.style.setProperty('z-index', '1');
  }

  render() {
    if (this.data == null) {
      return nothing;
    }

    return html`
      <nn-sources-tooltip
        id=${this.tooltipRef}
        tooltip-source-number=${this.tooltipSourceNumber}
        tooltip-pdf-url=${this.data.pdf_url}
        tooltip-title=${this.data.title}
        tooltip-description=${this.data.desc}
        pdf-width="300"
        pdf-height="350"
        @mouseover="${this.mouseOver}"
        @mouseout="${this.mouseOut}"
      ></nn-sources-tooltip>
    `;
  }
}

export { AdeleSourcesTooltip };
