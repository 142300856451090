/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { PropertyValues, TemplateResult } from 'lit';
import { property, state } from 'lit/decorators';
import {
  CSSResult,
  NNBase,
  html,
  nothing,
} from '@mch/nn-web-viz/dist/packages/base/Base';

// Vaadin Components
import { Notification } from '@vaadin/notification';

// Driver
import { driver } from 'driver.js';

// Router
import { navigate } from '@mch/nn-web-viz/dist/packages/router';

// Store
import {
  sendEvent,
  trackPageView,
} from '@mch/nn-web-viz/dist/packages/analytics';
import { connect, store } from '../../../state/store';
import { modelFunctionsClient } from '../../../modules/functions/client';

// Styles
import { AdeleHomeStyles } from '../src/AdeleHomeStyles';

// Web Viz Components
import '@mch/nn-web-viz/dist/nn-tiles';
import '@mch/nn-web-viz/dist/nn-button';
import '@mch/nn-web-viz/dist/nn-spinner';

// Components
import '../../../components/adele-result';
import '../../../components/adele-source-agents';
import '../../../components/adele-search-input';
import '../../../components/adele-file-upload-overlay';
import { TermsPopup } from '../../../TermsPopup';
import '../../../terms-popup';

// State
import {
  setCurrentThread,
  setSelectedThread,
  setThreadItems,
  ThreadState,
} from '../../../state/slices/thread';
import {
  clearUserQuery,
  UserQueryState,
} from '../../../state/slices/userQuery';

// Utils
import {
  createWebSocketInstance,
  webSocketHandleAttachments,
  webSocketHandleCitations,
  webSocketHandleImgUrls,
  webSocketHandleRecommendation,
  webSocketHandleResponsepart,
  webSocketHandleTooltipRefs,
  webSockethandleStatus,
} from '../../../utils/websocket';

// Service
import ThreadService from '../../../service/ThreadService';

// Analytics
import { FileUploadOverlayMode } from '../../../components/upload-file-overlay/enums';
import { getQueryParams, routePrefix } from '../../../utils';
import MongoService from '../../../service/MongoService';
import { AccountState } from '../../../state/slices/accounts';
import { ChatModelsState } from '../../../state/slices/chatModels';

const worker = new Worker(new URL('../../../sw.js', import.meta.url));

interface User {
  id: number;
  name: string;
}

const handleError = data => {
  console.error('WebSocket Error:', data?.content || 'Unknown error');

  Notification.show(
    'There was a problem connecting to server. Please try again.',
    {
      position: 'bottom-end',
      duration: 10000,
      theme: 'error',
    }
  );
};

const renderSearchButton = () => html`
  <button
    class="search__button"
    type="submit"
    name="search-submit"
    aria-label="Search"
  >
    <svg
      class="search__icon"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M29.323 4.514a.243.243 0 0 1 0-.461l1.301-.434a1.942 1.942 0 0 0 1.229-1.228l.433-1.301a.243.243 0 0 1 .461 0l.434 1.301A1.942 1.942 0 0 0 34.41 3.62l1.3.434a.242.242 0 0 1 0 .46l-1.301.434a1.942 1.942 0 0 0-1.228 1.228l-.434 1.302a.243.243 0 0 1-.46 0l-.434-1.302a1.943 1.943 0 0 0-1.229-1.228l-1.301-.433Zm-3.427 2.595a.146.146 0 0 1 0-.277l.781-.26c.348-.116.621-.389.737-.737l.26-.78a.146.146 0 0 1 .277 0l.26.78a1.166 1.166 0 0 0 .737.737l.781.26a.145.145 0 0 1 .073.224.145.145 0 0 1-.073.053l-.78.26a1.166 1.166 0 0 0-.738.737l-.26.781a.146.146 0 0 1-.277 0l-.26-.78a1.163 1.163 0 0 0-.737-.738l-.78-.26Zm-.704-4.75a.098.098 0 0 1-.048-.148.098.098 0 0 1 .048-.035l.52-.174a.776.776 0 0 0 .491-.492l.174-.52a.098.098 0 0 1 .148-.047.098.098 0 0 1 .036.047l.173.52a.776.776 0 0 0 .492.492l.52.174a.098.098 0 0 1 .066.092.098.098 0 0 1-.066.092l-.52.173a.777.777 0 0 0-.492.492l-.173.52a.098.098 0 0 1-.149.047.098.098 0 0 1-.035-.048l-.174-.52a.776.776 0 0 0-.491-.492l-.52-.172Zm6.437 29.677-7.19-7.433A14.041 14.041 0 0 0 27.1 16.34c0-7.611-5.99-13.804-13.351-13.804C6.388 2.536.398 8.73.398 16.34c0 7.612 5.99 13.804 13.352 13.804a12.997 12.997 0 0 0 7.991-2.752l7.19 7.433c.364.337.839.516 1.327.502.488-.014.952-.22 1.297-.578.346-.356.545-.836.56-1.34a2.015 2.015 0 0 0-.486-1.373ZM4.213 16.34c0-1.95.56-3.856 1.607-5.478a9.613 9.613 0 0 1 4.28-3.631 9.246 9.246 0 0 1 5.51-.561c1.85.38 3.55 1.32 4.883 2.698a9.968 9.968 0 0 1 2.61 5.049 10.17 10.17 0 0 1-.542 5.697 9.796 9.796 0 0 1-3.513 4.425A9.315 9.315 0 0 1 13.75 26.2c-2.528-.003-4.952-1.043-6.74-2.891-1.788-1.849-2.794-4.355-2.797-6.969Z"
        fill="#fff"
      />
    </svg>
  </button>
`;

const renderBookSpinner = () => html`<div class="container">
  <nn-spinner theme="book"></nn-spinner>
</div>`;

class AdeleHome extends connect(store)(NNBase) {
  /**
   * @private
   * Current query from the user.
   */
  @property({ type: String }) _query: string = '';

  /**
   * @private
   * Response accumulator from the WebSocket.
   */
  @property({ type: String }) _response: string = '';

  /**
   * @private
   * Temporary response accumulator. Used in WebSocket and WebWorker.
   */
  @property({ type: String }) _tempResponse: string = '';

  /**
   * @private
   * Thread object to store the thread.
   */
  @property({ type: Object }) _thread: any | null = null;

  /**
   * @private
   * WebSocket instance.
   */
  @property({ type: Object }) _webSocket: WebSocket | null = null;

  /**
   * @private
   * WebSocket status.
   */
  @property({ type: String }) _webSocketStatus: string = 'CLOSED';

  /**
   * @private
   * Flag to check if the WebSocket is closing.
   */
  @property({ type: Boolean }) _closingWebSocket: boolean = false;

  /**
   * @private
   * Suggested query for the user.
   */
  @property({ type: String }) _suggestedQuery: string =
    'Targeted Therapies in Oncology';

  /**
   * @private
   * Flag to show the sources popup.
   */
  @property({ type: Boolean }) _showSourcesPopup = false;

  /**
   * @private
   * Flag to show the sources popup.
   */
  @property({ type: Boolean }) _showAgentsPopup = false;

  /**
   * @private
   * Selected source. Always in sync with the state across the app.
   */
  @property({ type: Number }) _selectedSource: number | null = null;

  /**
   * @private
   * Selected agent. Always in sync with the state across the app.
   */
  @property({ type: Number }) _selectedAgent: number | null = null;

  /**
   * @private
   * Current selected account.
   */
  @property({ type: Object }) _currentAccount: User | null = null;

  /**
   * @private
   * Flag to check if the WebSocket is in progress.
   */
  @property({ type: Boolean }) _webSocketInProgress: boolean = false;

  /**
   * @private
   * Flag to check if the service worker is in progress.
   */
  @property({ type: Boolean }) _serviceWorkerInProgress: boolean = false;

  /**
   * @private
   * Flag to check if the user can upload files.
   */
  @property({ type: Boolean }) _canUpload: boolean = false;

  /**
   * @private
   * Flag to check if the user is a vanilla user.
   */
  @property({ type: Boolean }) _vanilla: boolean = false;

  /**
   * @private
   * Interval ID for the animation.
   */
  private _intervalId: number | undefined;

  @property({ type: Number }) _currentLetterIndex = -1;

  @property({ type: String }) _currentSelectedThreadId: string | null = null;

  @property({ type: Boolean }) _threadLoading: boolean = false;

  /**
   * @private
   * Flag to check if chat models are loading.
   */
  @property({ type: Boolean }) _chatModelsLoading: boolean = false;

  @property({ type: String }) _searchQuery = '';

  private _threadService = new ThreadService();

  private _mongoService = new MongoService();

  @state() private charCount = 0;

  maxCharCount =
    3.7 /* approx charaters per token? */ * 3500; /* max tokens per call? */

  @state() private overCharLimit = this.charCount > this.maxCharCount;

  static styles: CSSResult[] = [AdeleHomeStyles];

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    trackPageView({ page: 'HOME_PAGE' });
  }

  async stateChanged(_state: {
    accounts: AccountState;
    userQuery: UserQueryState;
    thread: ThreadState;
    chatModels: ChatModelsState;
  }): Promise<void> {
    this._vanilla = _state.accounts.currentAccount?.vanilla || false;

    if (
      _state.userQuery.value != null &&
      _state.userQuery.value !== this._query
    ) {
      this._updateQuery(_state.userQuery.value);
    }

    if (this._currentAccount?.id !== _state.accounts.currentAccount?.id) {
      this._currentAccount = _state.accounts.currentAccount;
    }

    const selectedSourceChanged =
      this._selectedSource !== _state.accounts?.selectedSource || null;
    const selectedAgentChanged =
      this._selectedAgent !== _state.accounts?.selectedAgent || null;

    if (selectedSourceChanged && _state.accounts.selectedSource !== null) {
      this._selectedSource = _state.accounts.selectedSource;
    }

    if (selectedAgentChanged && _state.accounts.selectedAgent !== null) {
      this._selectedAgent = _state.accounts.selectedAgent;
    }

    if (_state.accounts.currentAccount?.canUpload !== this._canUpload) {
      this._canUpload = _state.accounts.currentAccount?.canUpload || false;
    }

    this._currentSelectedThreadId = _state.thread?.selected
      ? _state.thread.selected.toString()
      : null;
    this._chatModelsLoading = _state.chatModels?.chatModelsLoading;
  }

  async updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('_query')) {
      document.title = this._query !== '' ? `ADELe | ${this._query}` : 'ADELe';
      if (this._query !== '') {
        this._thread = {
          ...this._thread,
          title: this._query,
        };
      }

      if (this._webSocketStatus === 'CLOSED') {
        this._processQuery(this._query);
      }
    }

    if (changedProps.has('_webSocketStatus')) {
      if (this._webSocketStatus === 'CLOSED' && this._closingWebSocket) {
        this._closingWebSocket = false;
        this._thread = {
          ...this._thread,
          title: this._query,
        };
        this._response = '';
        this._processQuery(this._query);
      }
    }

    if (changedProps.has('_webSocket')) {
      if (this._webSocket != null && this._closingWebSocket) {
        this._webSocket.close();
      }
    }

    if (
      changedProps.has('_webSocketInProgress') ||
      changedProps.has('_serviceWorkerInProgress')
    ) {
      if (
        !this._webSocketInProgress &&
        !this._serviceWorkerInProgress &&
        this._query !== ''
      ) {
        if (this._thread?.messages?.length > 1) {
          await this._appendMessageToThread();
        } else {
          await this._createThread();
        }

        store.dispatch(setCurrentThread(this._thread));
        store.dispatch(clearUserQuery());
      }
    }

    if (changedProps.has('_selectedSource')) {
      const sourcesValue = this.shadowRoot?.getElementById('sourcesValue');
      const sourcesButton = this.shadowRoot?.getElementById('sourcesButton');

      if (sourcesValue == null || sourcesButton == null) return;

      sourcesValue.style.display =
        this._selectedSource != null && this._selectedSource !== -1
          ? 'flex'
          : 'none';
      sourcesButton.style.borderColor =
        this._selectedSource != null && this._selectedSource !== -1
          ? '#40ffd7'
          : 'transparent';
    }

    if (changedProps.has('_selectedAgent')) {
      const agentsValue = this.shadowRoot?.getElementById('agentsValue');
      const agentsButton = this.shadowRoot?.getElementById('agentsButton');

      if (agentsValue == null || agentsButton == null) return;

      agentsValue.style.display =
        this._selectedAgent != null && this._selectedAgent !== -1
          ? 'flex'
          : 'none';
      agentsButton.style.borderColor =
        this._selectedAgent != null && this._selectedAgent !== -1
          ? '#40ffd7'
          : 'transparent';
    }

    if (changedProps.has('_currentSelectedThreadId')) {
      const old = changedProps.get('_currentSelectedThreadId');
      if (old !== this._currentSelectedThreadId) {
        this._loadThreadById(this._currentSelectedThreadId);
      }
    }
  }

  constructor() {
    super();

    this._loadThreadFromQueryParams();
  }

  _loadThreadFromQueryParams() {
    const threadId = getQueryParams().get('threadId') || '';

    if (threadId !== '') {
      this._currentSelectedThreadId = threadId;
      this._loadThreadById(this._currentSelectedThreadId);
    } else {
      store.dispatch(setSelectedThread(null));
      this._thread = null;
    }
  }

  async _loadThreadById(threadId) {
    if (threadId == null) return;

    try {
      this._threadLoading = true;

      const result = await this._threadService.getThreadById(threadId);
      const messages = result.map(m => ({
        ...m,
        source_id: m.source_id ?? undefined,
        agent_id: m.agent_id ?? undefined,
        recommendations: m.recommendations ?? undefined,
        citations: m.citations ?? undefined,
        image_url: m.image_url ?? undefined,
        tooltip: m.tooltip ?? undefined,
        step: m.step ?? undefined,
        attachment: m.attachment ?? undefined,
      }));
      this._thread = {
        id: result?.[0]?.thread_id,
        title: result?.[0]?.question,
        messages: messages.sort((a, b) => a.id - b.id),
      };

      store.dispatch(setCurrentThread(this._thread));
      // this._currentSelectedThreadId = threadId;
    } finally {
      this._threadLoading = false;
    }
  }

  connectedCallback(): void {
    super.connectedCallback();
    this._startAnimation();

    this.addEventListener('click', this._clickWatcher);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    if (this._intervalId) {
      clearInterval(this._intervalId);
    }
    this.removeEventListener('click', this._clickWatcher);
  }

  private _startAnimation(): void {
    this._intervalId = window.setInterval(() => {
      const mask = this.shadowRoot?.querySelector('.mask');
      if (!mask) return;

      const current = mask.querySelector('span[data-show]');
      if (!current) return; // Add null check for 'current' variable

      const next = current.nextElementSibling || mask.firstElementChild; // This ensures it loops back to the first child.

      // Remove 'data-show' from current and set 'data-up', which means it's moving out of view.
      current.removeAttribute('data-show');
      current.setAttribute('data-up', '');

      // Add null check for 'next' variable
      if (next) {
        // Next element gets 'data-show'
        next.setAttribute('data-show', '');
      }

      // Reset the one that has moved up after it's no longer visible.
      // This might need a slight delay or be tied to the end of an animation event.
      setTimeout(() => {
        const up = mask.querySelector('span[data-up]');
        if (up) {
          up.removeAttribute('data-up');
        }
      }, 2450); // This timeout should be slightly less than the interval time to ensure it resets just before the next cycle.
    }, 2500);
  }

  _clickWatcher(e) {
    const composedPath = e.composedPath();

    if (
      !composedPath.includes(
        this.shadowRoot?.querySelector('#sourcesButton')
      ) &&
      !composedPath.includes(this.shadowRoot?.querySelector('#agentsButton')) &&
      !composedPath.includes(
        this.shadowRoot?.querySelector('adele-source-agents')
      )
    ) {
      this._showSourcesPopup = false;
      this._showAgentsPopup = false;
    }
  }

  async _updateQuery(value) {
    this._query = value;

    this._closeWebSocket();
    this._toggleServiceWorkerInProgress(false);
    this._toggleWebSocketInProgress(false);

    setTimeout(() => {
      this._processQuery(value);
    }, 1000);
  }

  _appendResponse({ message, complete, index }) {
    if (index > this._currentLetterIndex) {
      this._currentLetterIndex = index;
      this._response = `${this._response}${message}`;
      this._updateThread();
    }

    if (complete) {
      this._updateThread();
      this._toggleServiceWorkerInProgress(false);
    }
  }

  _updateThread() {
    const currentThreadIndex = this._thread.messages.length - 1;
    const latestMessage = {
      ...this._thread.messages[currentThreadIndex],
      question: this._query,
      answer: this._response,
    };

    this._thread = {
      ...this._thread,
      messages: this._thread.messages.map((message, index) =>
        index === currentThreadIndex ? latestMessage : message
      ),
    };

    this._updateCurrentMessage();
  }

  async _updateThreadObject() {
    this._thread = {
      ...this._thread,
    };
  }

  /**
   * This is a hotfix to reflect the changes
   * in the child component.
   */
  _updateCurrentMessage() {
    this._thread = {
      ...this._thread,
      currentMessage: this._response,
    };
  }

  async _receiveMessageFromWebSocket(event) {
    const data = JSON.parse(event.data);

    switch (data?.type) {
      case 'status':
        this._thread = webSockethandleStatus(
          data,
          this._thread,
          this._thread.messages.length - 1
        );
        break;

      case 'attachments':
        this._thread = webSocketHandleAttachments(
          data,
          this._thread,
          this._thread.messages.length - 1
        );
        break;

      case 'tooltip_refs':
        if (data.content.length !== 0) {
          const promiseAll = data.content.map(ref =>
            modelFunctionsClient.getCitation(ref)
          );
          const tooltipData = await Promise.all(promiseAll);

          this._thread = webSocketHandleTooltipRefs(
            tooltipData.reduce((acc, obj) => {
              const key = Object.keys(obj)[0];
              acc[key] = obj[key];
              return acc;
            }, {}),
            this._thread,
            this._thread.messages.length - 1
          );
        }
        break;

      case 'response_part':
        this._tempResponse = webSocketHandleResponsepart(
          data,
          this._tempResponse
        );

        worker.postMessage({
          response: this._tempResponse,
          finished: false,
        });

        // eslint-disable-next-line @typescript-eslint/no-shadow, no-shadow
        worker.onmessage = event => {
          this._appendResponse(event.data);
        };

        break;

      case 'citations':
        this._thread = webSocketHandleCitations(
          data,
          this._thread,
          this._thread.messages.length - 1
        );
        break;

      case 'recommendations':
        this._thread = webSocketHandleRecommendation(
          data,
          this._thread,
          this._thread.messages.length - 1
        );
        break;

      case 'img_urls':
        this._thread = webSocketHandleImgUrls(
          data,
          this._thread,
          this._thread.messages.length - 1
        );
        break;

      case 'error':
        handleError(data);
        this._stopProcessingResponse();
        this._sendFinishedMessageToServiceWorker();
        break;

      case 'end_session':
        this._toggleWebSocketInProgress(false);
        this._closeWebSocket();
        this._updateThreadObject();
        this._sendFinishedMessageToServiceWorker();
        break;

      case 'stop_response':
        this._toggleServiceWorkerInProgress(false);
        this._toggleWebSocketInProgress(false);
        this._updateThreadObject();
        this._closeWebSocket();
        this._sendFinishedMessageToServiceWorker();
        break;

      default:
        break;
    }

    this._updateThread();
  }

  _sendFinishedMessageToServiceWorker() {
    worker.postMessage({
      response: this._tempResponse,
      finished: true,
    });
  }

  _closeWebSocket() {
    if (this._webSocket == null) return;
    if (
      this._webSocket.readyState === WebSocket.CLOSED ||
      this._webSocket.readyState === WebSocket.CLOSING
    )
      return;

    this._webSocket.close();
  }

  _toggleServiceWorkerInProgress(value: boolean) {
    this._serviceWorkerInProgress = value;
  }

  async _initializeWebSocket() {
    this._webSocket = await createWebSocketInstance(
      this._receiveMessageFromWebSocket.bind(this),
      this._handleWebSocketStatus.bind(this)
    );
  }

  _handleWebSocketStatus(status) {
    this._webSocketStatus = status;
  }

  async _sendMessageToWebSocket(message) {
    if (this._webSocketStatus === 'READY') {
      if (this._webSocket !== null) {
        this._webSocket.send(JSON.stringify(message));
      }
    } else {
      await this._initializeWebSocket();

      if (
        this._webSocket !== null &&
        this._webSocket.readyState !== WebSocket.CONNECTING
      ) {
        this._webSocket.send(JSON.stringify(message));
      }
    }
  }

  _clearResponse() {
    this._response = '';
    this._tempResponse = '';
  }

  _toggleWebSocketInProgress(value: boolean) {
    this._webSocketInProgress = value;
  }

  async _processQuery(query) {
    if (query != null && query !== '' && !this._webSocketInProgress) {
      this._currentLetterIndex = -1;
      this._clearResponse();
      this._query = query;
      this._toggleWebSocketInProgress(true);
      this._toggleServiceWorkerInProgress(true);
      this._thread = {
        title: query,
        messages: [
          {
            source_id: this._selectedSource,
            agent_id: this._selectedAgent,
          },
        ],
      };

      const body = this._createMessageObject();

      this._sendMessageToWebSocket(body);
    }
  }

  async _searchQueryChanged(query) {
    if (query != null && query !== '' && !this._webSocketInProgress) {
      this._toggleServiceWorkerInProgress(true);
      this._toggleWebSocketInProgress(true);
      this._clearResponse();

      this._query = query;
      this._currentLetterIndex = -1;
      this._thread = {
        ...this._thread,
        messages: [
          ...this._thread.messages,
          {
            question: query,
            source_id: this._selectedSource,
            agent_id: this._selectedAgent,
          },
        ],
      };

      const body = this._createMessageObject();

      this._sendMessageToWebSocket({ ...body, thread_id: this._thread.id });
    }
  }

  async _createThread() {
    const payload = {
      question: this._query,
      answer: this._response,
      source: this._selectedSource,
      agent: this._selectedAgent,
      thread: this._thread,
    };
    const threadId = await this._mongoService.createThread(this._query);

    this._mongoService.postMessages({
      ...payload,
      threadId,
    });
    this._thread = {
      ...this._thread,
      id: threadId,
    };
    this._loadThreads();
  }

  async _appendMessageToThread() {
    const payload = {
      question: this._query,
      answer: this._response,
      source: this._selectedSource,
      agent: this._selectedAgent,
      thread: this._thread,
    };

    await this._mongoService.postMessages({
      ...payload,
      threadId: this._thread.id,
    });
  }

  _createMessageObject() {
    const chatToken =
      store.getState().accounts.currentAccount?.tokens?.chatToken;

    return {
      chatToken,
      messages: [{ role: 'user', content: this._query }],
      model: store.getState().chatModels.selected,
      prompt:
        "You are ChatGPT, a large language model trained by OpenAI. Follow the user's instructions carefully. Respond using markdown.",
      key: '',
      temperature: '1',
      source_id:
        this._selectedSource != null && this._selectedSource !== -1
          ? this._selectedSource
          : store.getState().accounts.currentAccount?.defaultSource,
      agent_id: this._selectedAgent,
    };
  }

  async _handleSubmit(event) {
    event.preventDefault();

    sendEvent(event, {
      id: 'adele-search-input__new-thread-event',
      description: 'Create a new thread from home page',
    });

    this._processQuery(this._searchQuery);
  }

  async _submitSuggestedQuery(e) {
    sendEvent(e);

    await this._processQuery(this._suggestedQuery);
  }

  /**
   * Load threads to reflect the list on all components.
   */
  async _loadThreads() {
    const result = await this._threadService.getSortedThreads();

    store.dispatch(setThreadItems(result));
  }

  _renderCharCount() {
    let charCountStyle = 'color: #ffffff';

    if (this.charCount > this.maxCharCount) {
      charCountStyle = 'color: #ff4500';
    } else if (this.charCount > this.maxCharCount * 0.9) {
      charCountStyle = 'color: var(--lumo-warning-color)';
    }

    return html`
      <div
        style="align-self: flex-start; margin: 0px 5rem; padding: 5px; ${charCountStyle}"
      >
        Character count: ${this.charCount} /
        ${this.maxCharCount}${this.overCharLimit
          ? `. Please reduce your prompt.`
          : null}
      </div>
    `;
  }

  async _submitSearch(e) {
    sendEvent(e);
    this._searchQuery = e.detail;

    await this._processQuery(this._searchQuery);
  }

  _searchInputChanged(e) {
    this.charCount = e.detail.length;
    this.overCharLimit = this.charCount > this.maxCharCount;

    const inputField = this.shadowRoot?.querySelector(
      'adele-search-input'
    ) as any;

    if (inputField != null) {
      inputField.overCharLimit = this.overCharLimit;
    }

    this._searchQuery = e.detail;
  }

  _renderNewSearchInput() {
    return html`
      <div class="search__wrapper">
        ${this._renderVanillaUpload()} ${renderSearchButton()}
        <adele-search-input
          ?disabled=${!!store.getState().chatModels.selected}
          nn-analytics-id="adele-search-input__new-thread-event"
          nn-analytics-description="Create a new thread from home page"
          class="search-input"
          @search=${this.overCharLimit ? null : this._submitSearch}
          @model-value-changed=${this._searchInputChanged}
        ></adele-search-input>
        ${this._renderActionButtons()}
      </div>
    `;
  }

  async _createThreadFromFileUpload({
    detail: { query, threadId, files },
  }): Promise<void> {
    const result = await this._mongoService.postMessages({
      question: query,
      answer: `
       \n\n The following files has been successfully uploaded to server: \n\n
       \n - ${files.join(' \n - ')}`,
      source: this._selectedSource,
      agent: this._selectedAgent,
      thread: {
        messages: [
          {
            recommendations: [],
            citations: [],
            image_url: [],
            tooltip: [],
            step: [],
          },
        ],
      },
      threadId,
    });

    if (this._thread != null) {
      this._thread = {
        ...this._thread,
        messages: [
          ...this._thread.messages,
          {
            thread_id: threadId,
            question: query,
            answer: `
        \n\n The following files has been successfully uploaded to server: \n\n
        \n - ${files.join(' \n - ')}`,
            recommendations: [],
            citations: [],
            image_url: [],
            tooltip: [],
            step: [],
          },
        ],
      };
    }

    if (result?.status.toUpperCase() === 'SUCCESS') {
      this._loadThreads();
      navigate(`${routePrefix}/threads`);
      store.dispatch(setSelectedThread(threadId));
      window.history.replaceState({}, '', `/app/threads?threadId=${threadId}`);
    }
  }

  _renderVanillaUpload() {
    if (!this._vanilla) return nothing;

    return html` <nn-overlay
      .config=${{
        hidesOnOutsideClick: true,
      }}
    >
      <button
        nn-analytics-id="upload-file-button"
        nn-analytics-description="Upload file button"
        @click=${e => sendEvent(e)}
        class="search__upload"
        slot="invoker"
      >
        <svg
          width="39"
          height="38"
          viewBox="0 0 39 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x=".844"
            y=".212"
            width="37.966"
            height="37.423"
            rx="10.088"
            fill="#ffffff10"
          />
          <g clip-path="url(#a)">
            <path
              d="M21.42 23.94c0 .6-.483 1.084-1.083 1.084s-1.084-.483-1.084-1.084V11.384l-4.289 4.289a1.083 1.083 0 0 1-1.53-1.53l6.135-6.141a1.08 1.08 0 0 1 1.531 0l6.145 6.14a1.083 1.083 0 0 1-1.53 1.53l-4.29-4.288V23.94h-.005Zm1.445-.361v-2.167h6.14a2.892 2.892 0 0 1 2.89 2.89v3.611a2.892 2.892 0 0 1-2.89 2.89H11.669a2.892 2.892 0 0 1-2.89-2.89v-3.612a2.892 2.892 0 0 1 2.89-2.89h6.14v2.168h-6.14a.724.724 0 0 0-.722.722v3.612c0 .398.325.723.722.723h17.338a.725.725 0 0 0 .722-.723v-3.612a.725.725 0 0 0-.722-.722h-6.14Zm3.251 2.528a1.084 1.084 0 1 1 2.168 0 1.084 1.084 0 0 1-2.168 0Z"
              fill="#fff"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M8.778 7.686h23.117v23.117H8.778z" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <adele-file-upload-overlay
        slot="content"
        mode=${FileUploadOverlayMode.UPLOAD}
        @files-uploaded=${this._createThreadFromFileUpload}
      ></adele-file-upload-overlay>
    </nn-overlay>`;
  }

  _toggleSourcesDropdown(e) {
    sendEvent(e);

    this._showSourcesPopup = !this._showSourcesPopup;
    this._showAgentsPopup = false;
  }

  _toggleAgentsDropdown(e) {
    sendEvent(e);

    this._showAgentsPopup = !this._showAgentsPopup;
    this._showSourcesPopup = false;
  }

  _renderSourcesAndAgentsButton() {
    if (this._vanilla) return nothing;

    return html`<div class="source-agents__wrapper">
      ${this._renderSourcesButton()} ${this._renderAgentsButton()}
    </div> `;
  }

  _renderSourcesButton() {
    return html`
      <div
        @click=${this._toggleSourcesDropdown}
        @keyup=${this._toggleSourcesDropdown}
        nn-analytics-id="sources-button"
        nn-analytics-description="${!this._showSourcesPopup
          ? 'Open'
          : 'Close'} sources popup"
        class="main-container"
        id="sourcesButton"
      >
        <span>SOURCES</span>
        <div id="sourcesValue">
          <span>+1</span>
        </div>
      </div>
    `;
  }

  _renderAgentsButton() {
    return html`
      <div
        nn-analytics-id="agents-button"
        nn-analytics-description="${!this._showAgentsPopup
          ? 'Open'
          : 'Close'} agents popup"
        @click=${this._toggleAgentsDropdown}
        @keyup=${this._toggleAgentsDropdown}
        class="main-container"
        id="agentsButton"
      >
        <span>AGENTS</span>
        <div id="agentsValue">
          <span>+1</span>
        </div>
      </div>
    `;
  }

  _renderActionButtons() {
    return html`
      <div class="search__actions">
        <div id="sourcesAgentsButton" style="display:flex; ">
          ${this._renderSourcesAndAgentsButton()}
        </div>

        ${this._renderUploadButton()}

        <button ?disabled=${this.overCharLimit} class="search__send">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="39"
            height="38"
            viewBox="0 0 39 38"
            fill="none"
          >
            <rect
              x="0.636719"
              y="0.211914"
              width="37.9657"
              height="37.4233"
              rx="10.0885"
              fill="#ffffff10"
            />
            <path
              d="M18.449 18.9245L13.9149 18.9245M28.467 18.4099C28.5634 18.4568 28.6447 18.53 28.7016 18.621C28.7584 18.7119 28.7885 18.8171 28.7884 18.9244C28.7883 19.0316 28.7581 19.1367 28.7011 19.2276C28.6441 19.3185 28.5627 19.3915 28.4662 19.4384L11.196 27.7553C11.0927 27.805 10.9769 27.8225 10.8636 27.8056C10.7502 27.7887 10.6446 27.7381 10.5604 27.6603C10.4761 27.5826 10.4172 27.4814 10.3912 27.3698C10.3652 27.2582 10.3734 27.1413 10.4146 27.0344L13.4547 19.1293C13.5054 18.9975 13.5054 18.8515 13.4547 18.7197L10.4154 10.8138C10.3745 10.707 10.3666 10.5904 10.3927 10.4791C10.4187 10.3677 10.4776 10.2668 10.5617 10.1892C10.6457 10.1117 10.7511 10.0612 10.8641 10.0441C10.9772 10.0271 11.0928 10.0443 11.196 10.0937L28.467 18.4099Z"
              stroke=${this.overCharLimit ? '#666' : 'white'}
              stroke-width="3.39973"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    `;
  }

  _renderUploadButton() {
    if (!this._canUpload) return nothing;

    return html` <nn-overlay
      .config=${{
        hidesOnOutsideClick: true,
      }}
    >
      <button
        nn-analytics-id="create-source-button"
        nn-analytics-description="Create source"
        @click=${e => sendEvent(e)}
        class="search__upload"
        slot="invoker"
      >
        <svg
          width="39"
          height="38"
          viewBox="0 0 39 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x=".844"
            y=".212"
            width="37.966"
            height="37.423"
            rx="10.088"
            fill="#ffffff10"
          />
          <g clip-path="url(#a)">
            <path
              d="M21.42 23.94c0 .6-.483 1.084-1.083 1.084s-1.084-.483-1.084-1.084V11.384l-4.289 4.289a1.083 1.083 0 0 1-1.53-1.53l6.135-6.141a1.08 1.08 0 0 1 1.531 0l6.145 6.14a1.083 1.083 0 0 1-1.53 1.53l-4.29-4.288V23.94h-.005Zm1.445-.361v-2.167h6.14a2.892 2.892 0 0 1 2.89 2.89v3.611a2.892 2.892 0 0 1-2.89 2.89H11.669a2.892 2.892 0 0 1-2.89-2.89v-3.612a2.892 2.892 0 0 1 2.89-2.89h6.14v2.168h-6.14a.724.724 0 0 0-.722.722v3.612c0 .398.325.723.722.723h17.338a.725.725 0 0 0 .722-.723v-3.612a.725.725 0 0 0-.722-.722h-6.14Zm3.251 2.528a1.084 1.084 0 1 1 2.168 0 1.084 1.084 0 0 1-2.168 0Z"
              fill="#fff"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M8.778 7.686h23.117v23.117H8.778z" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <adele-file-upload-overlay slot="content"></adele-file-upload-overlay>
    </nn-overlay>`;
  }

  _renderSourcesAndAgentsPopup() {
    if (!this._showSourcesPopup && !this._showAgentsPopup) return nothing;

    return html`
      <adele-source-agents
        style="--source-agents-absolute-right: 110px; --source-agents-absolute-bottom: 85px;"
        .currentAccount=${this._currentAccount}
        .display=${this._showSourcesPopup ? 'sources' : 'agents'}
        .selectedAgent=${this._selectedAgent}
        .selectedSource=${this._selectedSource}
        @source-changed=${this._setSelectedSource}
        @agent-changed=${this._setSelectedAgent}
      ></adele-source-agents>
    `;
  }

  _renderSearchFieldForm() {
    return html` <form
      id="search-form"
      class="search"
      @submit=${this._handleSubmit}
    >
      <label class="search__label" for="search"></label>
      <div class="search__input-wrap">
        ${this._renderSourcesAndAgentsPopup()} ${this._renderNewSearchInput()}
      </div>
      ${this._renderCharCount()}
    </form>`;
  }

  _renderAdeleResult() {
    return html` <adele-result
      .data=${this._thread}
      .selectedSource=${this._selectedSource}
      .selectedAgent=${this._selectedAgent}
      ?answer-loading=${this._serviceWorkerInProgress ||
      this._webSocketInProgress}
      @search-query-changed=${e => this._searchQueryChanged(e.detail.data)}
      @selected-source-changed=${this._setSelectedSource}
      @stop-search=${this._stopProcessingResponse}
      @files-uploaded=${this._createThreadFromFileUpload}
    ></adele-result>`;
  }

  _renderSuggestedQuery() {
    if (this._vanilla) return nothing;

    return html`
      <div class="try-asking">
        <p>Try asking:</p>
        <nn-button
          nn-analytics-id="suggested-query-button"
          nn-analytics-description="Submit suggested query"
          @click=${this._submitSuggestedQuery}
          id="search1"
          style="border-radius: 99px; margin-left: 12px;"
          inner-color="transparent"
          outlined=""
          role="button"
          type="button"
          aria-disabled="false"
          ghost
          ><p class="ask-title">${this._suggestedQuery}</p></nn-button
        >
      </div>
    `;
  }

  _renderTourButton() {
    if (this._vanilla) return nothing;

    return html`
      <div class="start-tour">
        <button class="start-tour-button" @click=${this._startTour}>
          Start Tour
        </button>
      </div>
    `;
  }

  _renderQueryForm(): TemplateResult {
    return html`
      <div class="page-wrapper">
        <h1 class="greeting">
          <strong>
            <div style="margin-bottom:-140px;">Meet ADELe</div>
          </strong>
          <br />
          The Future of
          <div class="mask">
            <span data-show>Knowledge</span>
            <span>Insight</span>
            <span>Intelligence</span>
            <span>Discovery</span>
            <span>Expertise</span>
            <span>Information</span>
          </div>
          <br />
        </h1>

        ${this._renderSearchFieldForm()}

        <div class="fine-print">
          <p>
            ADELe can make mistakes. Consider checking important information.
          </p>
          <button
            nn-analytics-id="terms-button"
            nn-analytics-description="Show terms and conditions popup"
            class="see-terms-button"
            @click=${this._showTerms}
          >
            See Terms and Conditions
          </button>
        </div>
        ${this._renderSuggestedQuery()} ${this._renderTourButton()}
        <terms-popup></terms-popup>
      </div>
    `;
  }

  render(): TemplateResult {
    if (this._threadLoading || this._chatModelsLoading) {
      return renderBookSpinner();
    }

    if (this._thread) {
      return this._renderAdeleResult();
    }

    return this._renderQueryForm();
  }

  _setSelectedSource({ detail: { data } }) {
    this._selectedSource = data;
  }

  _setSelectedAgent({ detail: { data } }) {
    this._selectedAgent = data;
  }

  _stopProcessingResponse() {
    this._updateThreadObject();
    this._closeWebSocket();
    worker.postMessage({
      response: this._tempResponse,
      abort: true,
    });
    this._toggleWebSocketInProgress(false);
    this._toggleServiceWorkerInProgress(false);
  }

  _startTour() {
    const searchElement = this.shadowRoot?.querySelector('#search');
    const searchButton = this.shadowRoot?.querySelector('#search-submit');
    const sourcesButton = this.shadowRoot?.querySelector(
      '#sourcesAgentsButton'
    );

    const applyCustomStyles = popover => {
      popover.wrapper.classList.add('driver-popover-custom');
      // You can also apply styles directly if needed
      popover.wrapper.style.borderRadius = '24px';
      popover.wrapper.style.background =
        'linear-gradient(237deg, rgba(28, 31, 40, 0.1) 5.65%, rgba(28, 31, 40, 0.3) 85.87%, rgba(28, 31, 40, 0.5) 97.63%)';
      popover.wrapper.style.boxShadow = '0px 24px 30px 0px rgba(0, 0, 0, 0.05)';
    };

    const driverObj = driver({
      popoverClass: 'driver-popover-custom', // Use your custom class
      stagePadding: 10,
      stageRadius: 24,
      showProgress: true,
      overlayColor: 'rgb(0, 0, 0)',
      nextBtnText: 'Next',
      prevBtnText: 'Back',
      doneBtnText: 'Done',
      onPopoverRender: popover => {
        applyCustomStyles(popover);
      },
      steps: [
        {
          element: searchElement || undefined,
          popover: {
            title: 'Go ahead, ask something.',
            description:
              'Try to be as straightforward and directive as possible. Specify a clinical paper or output format.  Consult the User Guide for more prompting tips.',

            side: 'top',
            align: 'center',
          },
        },
        {
          element: sourcesButton || undefined,
          popover: {
            title: 'Select a Source',
            description:
              'ADELe can be loaded with different types of knowledge. Select the data source you would like to recieve answers from. Clinical Studies is the default source for the DSI Zaherity team.',
            side: 'top',
            align: 'end',
          },
        },

        {
          element: searchButton || undefined,
          popover: {
            title: 'Ask Question',
            description: 'Click the send button to ask the question.',
          },
        },
      ],
    });
    driverObj.drive();
  }

  _showTerms(e) {
    sendEvent(e);

    const termsPopup =
      this.shadowRoot?.querySelector<TermsPopup>('terms-popup');
    if (termsPopup) {
      termsPopup.visible = true;
    }
  }
}

export { AdeleHome };
