// eslint-disable-next-line import/no-extraneous-dependencies
import '@vaadin/details';
import { PropertyDeclarations } from 'lit';
import { NNBase, html, css } from '../../base/Base';

import { chevronUp } from '../../icons/chevron-up';
import { chevronDown } from '../../icons/chevron-down';
import { spinner } from '../../icons/spinner';
import { check } from '../../icons/check';

interface Step {
  id: string | number;
  label: string;
}

class NNDetails extends NNBase {
  static properties: PropertyDeclarations = {
    ...super.properties,
    currentStep: { type: String, attribute: 'current-step' },
    items: { type: Array },
    opened: { type: Boolean },
    loading: { type: Boolean },
    steps: { type: Array },
    openedTitle: { type: String, attribute: 'opened-title' },
    closedTitle: { type: String, attribute: 'closed-title' },
    closedSubtitle: { type: String, attribute: 'closed-subtitle' },
  };

  declare currentStep: string;

  declare opened: boolean;

  declare loading: boolean;

  declare steps: Array<Step>;

  declare openedTitle: string;

  declare closedTitle: string;

  declare closedSubtitle: string;

  static styles: any = [
    css`
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      .summary {
        cursor: pointer;
      }

      .flex {
        display: flex;
      }

      .items-center {
        align-items: center;
      }

      .gap-1 {
        gap: 0.25rem;
      }

      .gap-2 {
        gap: 0.5rem;
      }

      .summary__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.02em;
      }

      .summary__header.opened {
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
      }

      .spinner {
        animation: spin 2s linear infinite;
      }

      nn-icon {
        height: 1rem;
        width: 1rem;
        margin: 0px 0.5rem;
      }

      .details {
        margin-top: 0.5rem;
      }

      vaadin-details {
        background: rgba(28, 31, 40, 0.5);
        border-radius: 16px;
        border: 2px solid transparent;
        padding: 10px 8px 10px 12px;
        backdrop-filter: blur(18px);
      }
    `,
  ];

  constructor() {
    super();

    this.loading = false;
  }

  _toggleOpened(e) {
    const { value } = e.detail;
    this.opened = value;

    this.dispatchEvent(new CustomEvent('toggle-changed'));
  }

  _renderDetailsClosed() {
    const chevronUpSvg: any = chevronUp;
    const checkSvg: any = check;

    return html`<div class="summary__header">
      <div class="flex items-center">
        <nn-icon .svg=${checkSvg}></nn-icon>
        <div>${this.closedTitle}</div>
      </div>
      <div class="flex items-center gap-2">
        <span>${this.closedSubtitle}</span>
        <nn-icon .svg=${chevronUpSvg}></nn-icon>
      </div>
    </div>`;
  }

  render() {
    const chevronDownSvg: any = chevronDown;
    const spinnerSvg: any = spinner;

    return html`<vaadin-details
      ?opened=${this.opened}
      @opened-changed=${this._toggleOpened}
    >
      <div slot="summary" class="summary">
        ${this.opened
          ? html`<div class="summary__header opened">
              <div class="flex items-center gap-1">
                <div class="spinner" ?hidden=${!this.loading}>
                  <nn-icon .svg=${spinnerSvg}></nn-icon>
                </div>

                <div>${this.openedTitle}</div>
              </div>
              <nn-icon .svg=${chevronDownSvg}></nn-icon>
            </div>`
          : this._renderDetailsClosed()}
      </div>
      <div class="details">
        <slot name="steps"></slot>
      </div>
    </vaadin-details>`;
  }
}

export { NNDetails };
