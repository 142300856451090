import { CSSResultArray } from 'lit';
import { LionTooltip } from '@lion/tooltip';

import { NNTooltipStyles } from './NNTooltip.styles';

class NNTooltip extends LionTooltip {
  public static override get properties() {
    return {
      ...super.properties,
      backgroundColor: {
        type: String,
        reflect: true,
        attribute: 'background-color',
      },
      textColor: { type: String, reflect: true, attribute: 'text-color' },
      maxWidth: { type: String, reflect: true, attribute: 'max-width' },
      borderRadius: { type: String, reflect: true, attribute: 'border-radius' },
    };
  }

  public static override get styles(): CSSResultArray[] {
    return [super.styles, NNTooltipStyles];
  }

  declare backgroundColor: string;

  declare textColor: string;

  declare maxWidth: string;

  declare borderRadius: string;

  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('backgroundColor')) {
      this.style.setProperty(
        '--nn-tooltip-background-color',
        this.backgroundColor
      );
    }
    if (changedProperties.has('textColor')) {
      this.style.setProperty('--nn-tooltip-color', this.textColor);
    }
    if (changedProperties.has('maxWidth')) {
      this.style.setProperty('--nn-tooltip-max-width', this.maxWidth);
    }
    if (changedProperties.has('borderRadius')) {
      this.style.setProperty('--nn-tooltip-border-radius', this.borderRadius);
    }
  }
}

export { NNTooltip };
