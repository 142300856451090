import { css } from 'lit';

const AdeleHomeStyles = css`
  :host {
    box-sizing: border-box;
  }

  * {
    box-sizing: border-box;
  }
  
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .page-wrapper {
    border-radius: 24px;

    background: linear-gradient(
      237deg,
      rgba(28, 31, 40, 0.1) 5.65%,
      rgba(28, 31, 40, 0.3) 85.87%,
      rgba(28, 31, 40, 0.5) 97.63%
    );
    box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(18px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 10px;
  }

  h1 {
    color: #fff;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
  h1 strong {
    color: #de55de;
  }

  .search {
    margin: auto;
    width: 100%;
    max-width: 1136px;
    border-radius: 30px;
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 16px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0px solid #434346;
    -webkit-text-fill-color: #f1f1f1;
    -webkit-box-shadow: 0 0 0px 1000px rgba(48, 52, 64, 0.1) inset;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: white;
    background-color: transparent;
  }
  .search__icon {
    display: block;
    margin: auto;
    width: 35px;
    height: 35px;
  }
  .search__button:focus-visible .search__icon,
  .search__button:hover .search__icon {
    color: #fff;
  }
  .search__icon-g {
    transform: translate(6px, 6px);
    transform-origin: 12px 12px;
  }
  .search__icon-line2,
  .search__icon-line3 {
    transform-origin: 21px 21px;
  }
  .search__input {
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(18px);
    box-shadow: 0px 24px 30px 0px rgba(28, 31, 40, 0.1);
    padding-left: 120px;
    padding-right: 460px;
    width: 100%;
    height: 80px;
    font-size: 24px;
    color: #fff;
  }

  .search__input::placeholder {
    color: #fff;
  }
  .search__input-wrap {
    position: relative;
  }
  .search__label {
    position: absolute;
    width: 1px;
    height: 1px;
  }
  .search__upload {
    height: 100%;
    display: inline-block;
    cursor: pointer;
    margin: 0;
  }
  nn-overlay {
    height: 100%;
  }

  .search__send {
    height: 100%;
    display: inline-block;
    cursor: pointer;
    margin-left: 1rem;
  }
  .search__actions {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    right: 30px;
    height: 100%;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }
  .search button {
    background-color: transparent;
    border: 0;
    box-sizing: border-box;
    padding: 0;
  }
  .search__button,
  .search__input {
    outline: transparent;
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .search__button {
    background-color: transparent;
    cursor: pointer;
    display: block;
    width: 35px;
    height: 100%;
    margin-left: 1rem;
    -webkit-tap-highlight-color: transparent;
  }
  .search__input-wrap {
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    background-clip: padding-box;
    z-index: 1;
    border: solid 5px transparent;
    border-radius: 30px;
    width: 100%;
    height: auto;
  }

  .fine-print {
    color: #ffffff8f;
    display: flex;
    align-items: center;
  }

  .try-asking {
    color: #ffffff;
    display: flex;
  }
  .try-asking nn-button {
    display: inline-block;
    text-align: center;
  }
  .ask-title {
    color: #fff;
    padding: 0 4px;
    margin: 0;
    text-transform: capitalize;
  }

  .source-agents__wrapper {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0 1rem;
  }

  #sourcesButton,
  #agentsButton {
    cursor: pointer;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    flex-wrap: nowrap;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    border: 2px solid;
    border-color: transparent;
    position: relative;
    box-sizing: content-box;
    gap: 0.5rem;
  }

  #sourcesButton span,
  #agentsButton span {
    height: 16px;
    flex-shrink: 0;
    flex-basis: auto;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: #ffffff;
    letter-spacing: -0.24px;
    position: relative;
    text-align: left;
    white-space: nowrap;
  }

  #sourcesValue,
  #agentsValue {
    display: none;
    width: 35px;
    padding: 4px 10px 4px 10px;
    gap: 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-wrap: nowrap;
    border-radius: 99px;
    border: 1px solid #40ffd7;
    position: relative;
    z-index: 1;
  }

  #sourcesValue span,
  #agentsValue span {
    height: 14px;
    flex-shrink: 0;
    flex-basis: auto;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    color: #ffffff;
    position: relative;
    text-align: left;
    white-space: nowrap;
    z-index: 2;
  }

  .greeting {
    position: relative;
    max-width: 1136px;
    text-align: left;
    width: 100%;
  }

  .mask {
    height: 126px;
    position: relative;
    overflow: hidden;
    margin-top: 0px;
    margin-left: 16px;
    white-space: nowrap;
    display: inline-block;
    width: 500px;
    top: 45px;
  }

  .mask span {
    display: block;
    box-sizing: border-box;
    position: absolute;
    top: 150px;
    padding-bottom: 56px;

    background-size: 100% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-repeat: no-repeat;
    text-align: baseline;
  }

  .mask span[data-show] {
    transform: translateY(-100%);
    transition: 0.5s transform ease-in-out;
  }

  .mask span[data-up] {
    transform: translateY(-200%);
    transition: 0.5s transform ease-in-out;
  }

  .mask span:nth-child(1) {
    background-image: linear-gradient(45deg, #0ecffe 50%, #07a6f1);
  }

  .mask span:nth-child(2) {
    background-image: linear-gradient(45deg, #18e198 50%, #0ec15d);
  }

  .mask span:nth-child(3) {
    background-image: linear-gradient(45deg, #8a7cfb 50%, #633e9c);
  }

  .mask span:nth-child(4) {
    background-image: linear-gradient(45deg, #fa7671 50%, #f45f7f);
  }
  .mask span:nth-child(5) {
    background-image: linear-gradient(
      45deg,
      #00dfb1 50%,
      #00b68e
    ); /* Green shades */
  }
  .mask span:nth-child(6) {
    background-image: linear-gradient(
      45deg,
      #f9ea00 50%,
      #c6b800
    ); /* Yellow shades */
  }
  .start-tour-button {
    border-radius: 14px;

    background: #ffffff20;
    box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(18px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 50px;
    gap: 10px;
    color: #fff;
    border: 0px;
    font-size: 16px;
    cursor: pointer;
  }

  .see-terms-button {
    border-radius: 14px;

    background: #ffffff20;
    box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(18px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 35px;
    gap: 10px;
    color: #fff;
    border: 0px;
    font-size: 14px;
    cursor: pointer;
    margin-left: 20px;
  }

  .search__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(18px);
    box-shadow: 0px 24px 30px 0px rgba(28, 31, 40, 0.1);
    border-radius: 30px;
    padding: 1rem 2rem;
    margin: 0 5rem;
  }

  .search-input {
    margin: 1rem;
  }

  @media (max-width: 1024px) {
    .page-wrapper {
      padding: 1rem;
    }

    .search__wrapper {
      margin: 0 1rem;
    }
  }

  @media (max-width: 1500px) {
    h1 {
      font-size: 60px;
    }
    .greeting {
      width: auto;
    }
  }

  @media (max-width: 1300px) {
    h1 {
      font-size: 40px;
    }
    .greeting {
      width: auto;
    }
    .mask {
      height: 130px;
    }
  }
`;

export { AdeleHomeStyles };
