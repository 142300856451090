/* eslint-disable lit/attribute-value-entities */
import '../../../nn-overlay';
import '../../../nn-circle';
import '../../../nn-tooltip';

import { white50percent } from '../../styles';
import { NNBase, html, css } from '../../base/Base';

class NNSourcesTooltip extends NNBase {
  public static override get properties() {
    return {
      ...super.properties,
      pdfHeight: { type: Number, attribute: 'pdf-height' },
      pdfWidth: { type: Number, attribute: 'pdf-width' },
      tooltipSourceNumber: { type: String, attribute: 'tooltip-source-number' },
      tooltipTitle: { type: String, attribute: 'tooltip-title' },
      tooltipDescription: { type: String, attribute: 'tooltip-description' },
      tooltipPdfUrl: { type: String, attribute: 'tooltip-pdf-url' },
    };
  }

  static styles: any = [
    css`
      nn-circle {
        cursor: pointer;
      }

      .tooltip__content {
        max-width: 300px;
      }

      .tooltip__title {
        margin-bottom: 1rem;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.02em;
      }

      .tooltip__description {
        margin-top: 1rem;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.02em;
      }
    `,
  ];

  declare pdfHeight: number;

  declare pdfWidth: number;

  declare tooltipSourceNumber: string;

  declare tooltipTitle: string;

  declare tooltipDescription: string;

  declare tooltipPdfUrl: string;

  render() {
    return html`
      <nn-tooltip
        text-color="white"
        has-arrow
        .backgroundColor=${'rgba(0,0,0,0.8)'}
        .borderRadius=${'30px'}
        style="box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(18px);"
        .config=${{
          invokerRelation: 'description',
          popperConfig: {
            placement: 'right-end',
            modifiers: [
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['right-start', 'right'],
                },
              },
            ],
          },
        }}
      >
        <nn-circle
          slot="invoker"
          value="0.3"
          .color=${white50percent}
          size="small"
          style="color:black;"
          >${this.tooltipSourceNumber}</nn-circle
        >
        <div slot="content" class="tooltip__content">
          <div class="tooltip__title">${this.tooltipTitle || ''}</div>
          <embed
            src="${this.tooltipPdfUrl}#toolbar=0&navpanes=0&scrollbar=0"
            width=${this.pdfWidth || 300}
            height=${this.pdfHeight || 350}
          />
          <div class="tooltip__description">
            ${this.tooltipDescription || ''}
          </div>
        </div>
      </nn-tooltip>
    `;
  }
}

export { NNSourcesTooltip };
