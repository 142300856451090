/* eslint-disable import/no-extraneous-dependencies */
import { marked } from 'marked';
// eslint-disable-next-line import/extensions
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { PropertyDeclarations, nothing } from 'lit';
import { NNBase, html } from '../../base/Base';

class NNMarkdown extends NNBase {
  static properties: PropertyDeclarations = {
    ...super.properties,
    markdown: { type: String },
    styles: { type: Array },
  };

  declare markdown: string;

  declare styles: Array<CSSStyleSheet>;

  _mapStyles() {
    if (!this.styles) return nothing;

    return this.styles.map((s: any) => s.cssText).join(' ');
  }

  render() {
    if (!this.markdown) return nothing;

    return html`<style>
        ${this._mapStyles()}
      </style>
      ${unsafeHTML(
        marked.parse(this.markdown, { headerIds: false, mangle: false })
      )}`;
  }
}

export { NNMarkdown };
