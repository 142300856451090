/* eslint-disable class-methods-use-this */
/* eslint-disable lit-a11y/mouse-events-have-key-events */
/* eslint-disable lit/attribute-value-entities */
import { PropertyDeclarations, nothing } from 'lit';
import { NNBase, html, css } from '../../../base/Base';
import { empty } from '../../../icons/empty';
import { Link } from '../types';

import '../../../../nn-tooltip';
import '../../../../nn-overlay';

class NNLinkItem extends NNBase {
  static properties: PropertyDeclarations = {
    ...super.properties,
    data: { type: Object },
    showPdfPopup: { type: Boolean, attribute: 'show-pdf-popup' },
  };

  declare data: Link | undefined;

  declare showPdfPopup: boolean;

  constructor() {
    super();

    this.showPdfPopup = false;
  }

  static styles: any = [
    css`
      :host {
        display: flex;
        position: relative;
      }

      .flex {
        display: flex;
      }

      .justify-center {
        justify-content: center;
      }

      .items-center {
        align-items: center;
      }

      .gap-2 {
        gap: 8px;
      }

      .count {
        height: 24px;
        width: 24px;
        min-width: 24px;
        color: #000000;
        background-color: #ffffff50;
        border-radius: 99px;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0em;
      }

      .content {
        background-color: rgba(28, 31, 40, 0.5);
        padding: 6px 8px 6px 8px;
        border-radius: 8px;
      }

      nn-icon {
        width: 12px;
        min-width: 12px;
        height: 16px;
        min-height: 16px;
      }

      .label {
        cursor: pointer;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0em;
      }

      .wrapper {
        width: 100%;
      }

      .tooltip {
        box-shadow: 0px 24px 30px 0px rgba(0, 0, 0, 0.05);
        backdrop-filter: blur(18px);
      }

      .tooltip__content {
        max-width: 300px;
        z-index: 9999;
      }
    `,
  ];

  _dispatchLinkItemClicked() {
    this.dispatchEvent(
      new CustomEvent('link-item-clicked', {
        bubbles: true,
        composed: true,
        detail: {
          data: this.data,
        },
      })
    );
  }

  setHighZIndex() {
    this.style.setProperty('z-index', '9999');
  }

  _removeZIndex() {
    this.style.removeProperty('z-index');
  }

  render() {
    if (this.data == null) return nothing;

    const { count, label } = this.data;

    const emptySvg: any = empty;

    return html`
    <div class="wrapper flex items-center gap-2"
      @mouseover=${this.setHighZIndex}
      @mouseout=${this._removeZIndex}
    >
      <div class="count flex justify-center items-center">${count}</div>
        ${
          this.showPdfPopup
            ? html`
                <nn-tooltip
                  class="tooltip"
                  text-color="white"
                  has-arrow
                  .backgroundColor=${'rgba(0,0,0,0.8)'}
                  .borderRadius=${'30px'}
                  .config=${{
                    invokerRelation: 'description',
                    popperConfig: {
                      modifiers: [
                        {
                          name: 'flip',
                          enabled: true,
                          placement: 'right-end',
                          options: {
                            fallbackPlacements: [
                              'bottom',
                              'right-start',
                              'right',
                            ],
                            padding: 4,
                          },
                        },
                      ],
                    },
                  }}
                >
                  <div
                    slot="invoker"
                    class="wrapper content flex items-center gap-2"
                  >
                    <nn-icon .svg=${emptySvg}></nn-icon>
                    <div
                      class="label"
                      @click=${this._dispatchLinkItemClicked}
                      @keyup=${this._dispatchLinkItemClicked}
                    >
                      ${label}
                    </div>
                  </div>
                  <div slot="content" class="tooltip__content">
                    <div class="tooltip__title">${this.data.label || ''}</div>
                    <embed
                      src="${this.data.url}#toolbar=0&navpanes=0&scrollbar=0"
                      width="300"
                      height="350"
                    />
                    <div class="tooltip__description">
                      ${this.data.label || ''}
                    </div>
                  </div>
                </nn-tooltip>
              `
            : html`
                <div class="wrapper content flex items-center gap-2">
                  <nn-icon .svg=${emptySvg}></nn-icon>
                  <div
                    slot="invoker"
                    class="label"
                    @click=${this._dispatchLinkItemClicked}
                    @keyup=${this._dispatchLinkItemClicked}
                  >
                    ${label}
                  </div>
                </div>
              `
        }
      </div>
    </div>`;
  }
}

export { NNLinkItem };
