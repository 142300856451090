import { property } from 'lit/decorators';
import { LitElement, css, html, nothing } from 'lit';

import { sendEvent } from '@mch/nn-web-viz/dist/packages/analytics';

import { connect, store } from '../../../state/store';

import { arrowLeft } from '../../../assets/icons/arrowLeft';
import { arrowRight } from '../../../assets/icons/arrowRight';

import '@mch/nn-web-viz/dist/nn-button';
import '@mch/nn-web-viz/dist/nn-link';
import '@mch/nn-web-viz/dist/nn-link-item';
import '@mch/nn-web-viz/dist/nn-icon';

class AdeleImageViewer extends connect(store)(LitElement) {
  @property({ type: Array }) data: any = [];

  @property({ type: Number }) selected = 0;

  @property({ type: Boolean }) _nextDisabled = false;

  @property({ type: Boolean }) _prevDisabled = false;

  static styles = css`
    * {
      box-sizing: border-box;
      transition: all 0.15s ease-in;
    }

    :host {
      height: 100%;
      width: 100%;
      display: flex;
    }

    .container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 3rem 6rem;
      overflow-y: auto;
    }

    .header__actions,
    .footer__actions {
      display: flex;
      padding: 4px;
    }

    .image__container {
      height: 70%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .description {
      font-size: 12px;
    }

    .button__content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }

    .goback__button {
      cursor: pointer;
      display: flex;
      padding: 0.5rem 0.5rem;
      color: var(--lumo-primary-text-color);
      border-radius: 4px;
      text-decoration: none;
    }

    .back__container {
      display: flex;
    }

    .next-button,
    .prev-button {
      padding: 0.5rem 1.5rem;
    }

    .download-button {
      padding: 0.5rem 1.5rem;
      background-image: linear-gradient(310deg, #7928ca 0%, #ff0080 100%);
      color: var(--lumo-primary-text-color);
      border-radius: 4px;
      text-decoration: none;
    }

    .goback__button:hover,
    .download-button:hover {
      transform: scale(1.02);
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  `;

  updated(changedProps) {
    super.update(changedProps);

    if (changedProps.has('selected')) {
      this._nextDisabled = this.selected === this.data.length - 1;
      this._prevDisabled = this.selected === 0;
    }
  }

  _dispatchBackClicked(e) {
    sendEvent(e);

    this.dispatchEvent(new CustomEvent('back-clicked'));
  }

  _linkItemClicked({ detail }) {
    this.dispatchEvent(
      new CustomEvent('source-item-clicked', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  _renderCitation() {
    const { imageCitation } = this.data[this.selected];

    if (!imageCitation || imageCitation.length === 0) return nothing;

    const citationData = [
      {
        id: 0,
        count: 1,
        label: imageCitation, // Assuming imageCitation is a string
      },
    ];

    return html`
      <div class="sources">
        <h2>Citation</h2>
        <div>
          <nn-link
            style="display:block;max-width:600px;overflow-wrap: break-word;"
            .data=${citationData}
            @link-item-clicked=${this._linkItemClicked}
          ></nn-link>
        </div>
      </div>
    `;
  }

  render() {
    return html`<div class="container">
      <div class="back__container">
        <div
          class="goback__button"
          nn-analytics-id="image-viewer-back-button"
          nn-analytics-description="Image viewer back to results page button"
          @click=${this._dispatchBackClicked}
          @keyup=${this._dispatchBackClicked}
        >
          <div class="button__content">
            <nn-icon .svg=${arrowLeft}></nn-icon>
            <span>GO BACK</span>
          </div>
        </div>
      </div>
      <div class="header__actions">
        <nn-button
          class="prev-button"
          nn-analytics-id="image-viewer-previous-button"
          nn-analytics-description="Image viewer previous button"
          @click="${e => {
            sendEvent(e);
            this.selected -= 1;
          }}"
          ?disabled="${this._prevDisabled}"
          ><nn-icon .svg=${arrowLeft}></nn-icon
        ></nn-button>
        <nn-button
          class="next-button"
          nn-analytics-id="image-viewer-next-button"
          nn-analytics-description="Image viewer next button"
          @click="${e => {
            sendEvent(e);
            this.selected += 1;
          }}"
          ?disabled="${this._nextDisabled}"
          ><nn-icon .svg=${arrowRight}></nn-icon
        ></nn-button>
      </div>
      <div class="image__container">
        <img
          src="${this.data[this.selected].imageUrl}"
          alt="${this.data[this.selected].alt}"
        />
      </div>
      <p class="description">${this.data[this.selected].alt}</p>
      ${this._renderCitation()}
      <div class="footer__actions">
        <a
          class="download-button"
          href="${this.data[this.selected].imageUrl}"
          download="hello"
          >DOWNLOAD</a
        >
      </div>
    </div>`;
  }
}

export { AdeleImageViewer };
