import { svg } from 'lit';

const bookOpenReader = svg`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_6_3725)">
<path d="M8.0001 4.5C8.39792 4.5 8.77945 4.34196 9.06076 4.06066C9.34206 3.77936 9.5001 3.39782 9.5001 3C9.5001 2.60218 9.34206 2.22064 9.06076 1.93934C8.77945 1.65804 8.39792 1.5 8.0001 1.5C7.60227 1.5 7.22074 1.65804 6.93944 1.93934C6.65813 2.22064 6.5001 2.60218 6.5001 3C6.5001 3.39782 6.65813 3.77936 6.93944 4.06066C7.22074 4.34196 7.60227 4.5 8.0001 4.5ZM8.0001 0C8.79575 0 9.55881 0.31607 10.1214 0.87868C10.684 1.44129 11.0001 2.20435 11.0001 3C11.0001 3.79565 10.684 4.55871 10.1214 5.12132C9.55881 5.68393 8.79575 6 8.0001 6C7.20445 6 6.44139 5.68393 5.87878 5.12132C5.31617 4.55871 5.0001 3.79565 5.0001 3C5.0001 2.20435 5.31617 1.44129 5.87878 0.87868C6.44139 0.31607 7.20445 0 8.0001 0ZM7.1251 8.71875L7.11885 8.71562C7.09697 8.7 7.04385 8.66875 6.95635 8.625C6.78447 8.5375 6.4751 8.39687 6.0001 8.24375C5.1251 7.9625 3.68135 7.64062 1.5001 7.53438V13.0437C4.15322 13.2 6.09697 13.7406 7.2501 14.1594V8.8125L7.1251 8.71875ZM8.7501 8.8125V14.1594C9.90322 13.7406 11.847 13.2 14.5001 13.0437V7.53438C12.3157 7.64062 10.8751 7.9625 10.0001 8.24375C9.52822 8.39687 9.21885 8.5375 9.04385 8.625C8.95635 8.66875 8.90322 8.7 8.88135 8.71562L8.8751 8.71875L8.7501 8.8125ZM1.0001 6.01562C6.2251 6.16875 8.0001 7.5 8.0001 7.5C8.0001 7.5 9.7751 6.16875 15.0001 6.01562C15.5532 6 16.0001 6.45 16.0001 7V13.5C16.0001 14.0531 15.5501 14.4969 15.0001 14.5219C11.4095 14.6688 9.15947 15.575 8.4751 15.8906C8.3251 15.9594 8.16572 16 8.00322 16C7.84072 16 7.67822 15.9594 7.53135 15.8906C6.84697 15.575 4.59697 14.6688 1.00635 14.5219C0.453223 14.5 0.00634766 14.0531 0.00634766 13.5V7C0.00634766 6.44688 0.456348 5.99687 1.00635 6.01562H1.0001Z" fill="#C5C8E2"/>
</g>
<defs>
<clipPath id="clip0_6_3725">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>`;

export { bookOpenReader };
