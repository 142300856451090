import { svg } from 'lit';

const check = svg`
<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_207_137)">
<path d="M13.7813 3.21873C14.0751 3.51248 14.0751 3.98748 13.7813 4.2781L5.53135 12.5312C5.2376 12.825 4.7626 12.825 4.47197 12.5312L0.218848 8.28123C-0.0749023 7.98748 -0.0749023 7.51248 0.218848 7.22185C0.512598 6.93123 0.987598 6.9281 1.27822 7.22185L4.99697 10.9406L12.7188 3.21873C13.0126 2.92498 13.4876 2.92498 13.7782 3.21873H13.7813Z" fill="#C5C8E2"/>
</g>
<defs>
<clipPath id="clip0_207_137">
<rect width="14" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
`;

export { check };
