import { css } from '../../base/Base';

const NNCircleStyles: any = [
  css`
    :host {
      transition: all 0.3s ease;
    }
    :host(:hover) {
      transform: scale(1.08);
    }
    :host([outlined]) .circle {
      border: 1px solid var(--nn-circle-color, transparent);
      background-color: transparent;
    }
    :host([gradient][outlined]) .circle {
      width: var(--nn-circle-size, 96px);
      height: var(--nn-circle-size, 96px);
      border: 2px solid var(--nn-circle-color);
      border-radius: 100%;
      background-image: none;
    }
    ::slotted(*) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .circle {
      background-color: var(--nn-circle-color, transparent);
      background-image: var(--nn-circle-color, transparent);
      height: var(--nn-circle-size, 100px);
      width: var(--nn-circle-size, 100px);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--nn-circle-font-size, 16px);
    }
  `,
];

export { NNCircleStyles };
